import React, { RefObject, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { headers } from "../../../App/Axios"
import { RoleType } from "../../../App/enums"
import { not_found } from "../../../App/GlobaleVariables"
import { arrayOfLength, isDefined } from "../../../App/Helpers/Helpers"
import HTTPService from "../../../App/HTTPService"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import "../../../Styles/main.scss"
import FeedItem from "../../Shared/Components/FeedItem"
import SponsorCard from "../../Shared/Components/SponsorCard"
import { getUserDonationLink } from "../UserSettings/UserSettingsSlice"
import useWindowSize from "../../Shared/Hooks/useWindowSize"
import ArticleCard from "../../Shared/ArticleCard/ArticleCard"
import { User } from "../../../Types/User"
import { Room } from "../../../Types/Room"
import { useNavigate, useParams } from "react-router-dom"
import { LoadingAnimation, SearchIcon } from "../../Shared/Components/SvgIcons"
import UserDashboardButtons from "./UserDashboardButtons"
import { setUserRoomSortOption } from "../../Shared/SharedSlices/RoomSlice"
import { RoomPosts } from "../../../Types/RoomPosts"
import { bool } from "prop-types"
import FeedContainer from "../../Shared/Components/FeedContainer"
import { setRoomPostData, setRoomPostsData } from "../../Room/RoomPost/RoomPostSlice"
import FeedTextBox from "../../Shared/Components/FeedTextBox"
import TutorialBubble from "../../Shared/Components/TutorialBubble"

function UserDashboardFeed(props: { hasArticleCard: boolean }) {
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const userDonationLinkState = useSelector((state: RootState) => state.UserSettingsStatusSlice.userDonationLinkSlice.link)
    const translation = useSelector(selectTranslations)
    const posts = useSelector((state: RootState) => state.RoomPostsSlice.roomPostsSlice.posts)
    const [isLoading, setIsLoading] = useState(true)
    const { id } = useParams()
    const dispatch = useDispatch()
    const screenWidth = useWindowSize()
    const breakpoint = 1023
    const navigate = useNavigate()
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const [pageNum, setPageNum] = useState(1)
    const [hasNextPage, setHasNextPage] = useState(false)
    const bottom = useRef() as RefObject<HTMLDivElement>
    const scrollContainer = useRef() as RefObject<HTMLDivElement>
    const container = useRef() as RefObject<HTMLDivElement>

    const getDonationLinkRequest = () => {
        return HTTPService.get("/get-current-donation-url/", headers)
    }

    const GetFeedItemRequest = (pageNumber: number) => {
        return HTTPService.get(`/feed?page=${pageNumber}`, headers)
    }

    useEffect(() => {
        let isMounted = true

        if (userDataState && userDataState!.id !== null && userDataState!.id !== undefined) {
            setIsLoading(true)
            GetFeedItemRequest(pageNum)
                .then(response => {
                    if (isMounted && response.status === 200) {
                        setIsLoading(false)
                        dispatch(setRoomPostsData(response.data.results))
                        setHasNextPage(Boolean(response.data.next))

                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [dispatch, userDataState])

    useEffect(() => {
        if (!isDefined(userDonationLinkState)) {
            getDonationLinkRequest()
                .then(response => {
                    if (response.status === 200) {
                        dispatch(getUserDonationLink(response.data.current_donation_url))
                    }
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        dispatch(getUserDonationLink(not_found))
                    }
                })
        }
    }, [dispatch, userDonationLinkState])

    const onSeeAllEventsClick = () => {
        switch (role) {
            case RoleType.ORGANIZATION:
                navigate(`/all-premium-events/${id}`)
                break
            case RoleType.USER:
                navigate(`/all-premium-events`)
                break
            default:
                break
        }
    }

    const onScroll = () => {
        if (scrollContainer.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollContainer.current
            if (Math.ceil(scrollTop + clientHeight + 1) >= scrollHeight && hasNextPage && !isLoading) {
                setIsLoading(true)
                const controller = new AbortController()
                const { signal } = controller

                GetFeedItemRequest(pageNum + 1)
                    .then(response => {
                        if (response.status === 200) {
                            dispatch(setRoomPostsData([...posts, ...response.data.results]))
                            setHasNextPage(Boolean(response.data.next))
                            var topPos = bottom.current!.offsetTop
                            container.current!.scrollTop = topPos - 10
                            setPageNum(pageNum + 1)
                            setIsLoading(false)
                        }
                    })
                    .catch(error => {
                        if (signal.aborted) return
                        console.log(error)
                        setIsLoading(false)
                    })
                return () => controller.abort()
            }
        }
    }

    return (
        <div className="dashboard-feed" ref={container}>
            <div className="dashboard-feed__header">
                <div className="dashboard-feed__text">
                    <div className="dashboard-feed__text dashboard-feed__text--font-size-36 dashboard-feed__text--font-weight-700 dashboard-feed__text--margin-bottom-06">
                        {translation.hello} {userDataState.first_name},
                    </div>
                    <div className="dashboard-feed__text dashboard-feed__text--font-size-18 dashboard-feed__text--font-weight-500">
                        {translation.feedWelcomeText}
                    </div>
                    <UserDashboardButtons />
                </div>
            </div>
            <FeedTextBox />
            
            <div className={`dashboard-feed__content`}  onScroll={onScroll} ref={scrollContainer}>
            <TutorialBubble text={translation.tutorial_user_feed} modifier="feed" visible={userDataState.tutorial_progress == 2} hasNext={true} />
            {userDataState.tutorial_progress == 2 && (
                <div className="dashboard-feed__tutorial-active"/>
            )
            }
                {posts?.length === 0 && !isLoading ? (
                    <div className="dashboard-feed__header dashboard-feed__header--no-content-card">
                        <p className="dashboard-feed__text dashboard-feed__text--width-90 dashboard-feed__text--font-weight-500 dashboard-feed__text--margin-top-1">
                            {translation.noJoinedRooms1}{" "}
                            <span className="dashboard-feed__text dashboard-feed__text--link" onClick={() => navigate(`/rooms/`)}>
                                {" "}
                                {translation.roomSearch}
                            </span>{" "}
                            {translation.noJoinedRooms2}
                        </p>
                    </div>
                ) : (
                    <FeedContainer type={RoleType.USER} isLoading={isLoading} />
                )}
                {posts!.length > 0 && isLoading && (
                    <div style={{ width: "100%", display: "flex" }}>
                        <LoadingAnimation type="post" />
                    </div>
                )}
                {props.hasArticleCard && screenWidth < breakpoint && <ArticleCard />}
                <div className="organization-dashboard-feed__sponsor-title ">
                    {translation.sponsoredText}
                    <div onClick={onSeeAllEventsClick} className="main-layout__sponsored-icon">
                        <SearchIcon color="dark-blue" />
                    </div>
                </div>
                <div className="dashboard-feed__sponsor-card">
                    <SponsorCard />
                </div>
                <div style={{ float: "left", clear: "both" }} ref={bottom}></div>
            </div>
        </div>
    )
}

export default UserDashboardFeed
