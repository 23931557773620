import { StorageKey } from "../../../App/enums"
import HTTPService from "../../../App/HTTPService"
import StorageService from "../../../App/StorageService"
import { userNotificationGetRequest } from "../../Notifications/UserNotificationServiceRequest"
import { setUserNotifications } from "../../Notifications/NotificationSlice"
import { setUserData } from "../../Shared/SharedSlices/UserDataSlice"
import { getSigninError, hasAuthenticationToken, resetSigninError } from "./SigninSlice"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"

export const getUserRequest = (id: number) => {
    return HTTPService.get(`/profile/${id}/`)
}

export const signinPostRequest = (signinForm: object) => async (dispatch: Function) => {
    try {
        const response = await HTTPService.post("/login/", signinForm)
        if (response.status === 200) {
            const accessToken = response.data.tokens.access
            const refreshToken = response.data.tokens.refresh
            const id = response.data.id
            const tutorial_progress = response.data.tutorial_progress
            StorageService.setItem(StorageKey.accessToken, accessToken)
            StorageService.setItem(StorageKey.refreshToken, refreshToken)

            const showWelcomeMessage = tutorial_progress == 0 ? true : false

            await dispatch(resetSigninError())
            await dispatch(setModalData({ open: true, showTutorial: showWelcomeMessage }))

            getUserRequest(id)
                .then(response => {
                    if (response.status === 200) {
                        const user = {
                            id: response.data.id,
                            email: response.data.email,
                            location: response.data.location,
                            location_state: response.data.location_state_id,
                            cover_photo: response.data.cover_photo,
                            first_name: response.data.first_name,
                            last_name: response.data.last_name,
                            description: response.data.description,
                            edusiia_reasons: response.data.edusiia_reasons,
                            profile_photo: response.data.profile_photo,
                            privacy_settings: response.data.privacy_settings,
                            hashtags: response.data.hashtags,
                            tutorial_progress: response.data.tutorial_progress,
                            online: response.data.online
                        }

                        StorageService.setItem("user", StorageService.stringify(user))
                        dispatch(hasAuthenticationToken({ hasAccessToken: true }))
                        dispatch(setUserData(user))
                    }
                })
                .catch(error => {
                    console.log(error.response)
                })

            try {
                const response = await userNotificationGetRequest()
                if (response.status === 200) {
                    dispatch(setUserNotifications(response.data))
                }
            } catch (error: any) {
                console.log(error)
            }
        }
    } catch (error: any) {
        const errors = {
            msg: error.response.data,
            status: error.response.status,
        }

        await dispatch(
            getSigninError({
                message: errors.msg,
                status: errors.status,
            })
        )
    }
}
