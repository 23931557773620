import React, { useEffect, useState } from "react"
import Header from "../../../Shared/Header/Header"
import Footer from "../../../Shared/Layout/Footer"
import IndividualProfileCard from "../../../Shared/Components/IndividualProfileCard"
import MainLayout from "../../../Shared/Layout/MainLayout"
import ModalGroup from "../../../Shared/Components/ModalGroup"
import RoomDropdown from "../../../Shared/Components/RoomDropdown"
import OrganizationProfileCoverPhoto from "../OrganizationProfileCoverPhoto"
import OrganizationProfileEmployees from "./OrganizationProfileEmployees"
import OrganizationProfileHeader from "../OrganizationProfileHeader"
import { RoleType, StorageKey, SubscriptionType } from "../../../../App/enums"
import OrganizationProfilePinboardContainer from "../OrganizationPinboard/OrganizationProfilePinboardContainer"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../App/store"
import OrganizationProfileCard from "../../../Shared/Components/OrganizationProfileCard"
import GoPremiumCard from "../../../Shared/Components/GoPremiumCard"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import NewestOrganizationsCard from "../../../Shared/NewestOrganizations/NewestOrganizations"
import { setRoleData, setRoleStatusData } from "../../../Shared/SharedSlices/RolesSlice"
import SessionService from "../../../../App/SessionService"
import { Organization } from "../../../../Types/Organization"
import { setOrganizationData } from "../../../Shared/SharedSlices/OrganizationsSlice"
import BackgroundWrapper from "../../../Shared/Components/BackgroundWrapper"
import { getOrganizationData } from "../../OrganizationDashboard/GetOrganizationServiceRequest"

function OrganizationProfileEmployeesScreen() {
    const { id, otherOrgId } = useParams()
    const dispatch = useDispatch()
    const [isYourOrg, setIsYourOrg] = useState(false)
    const translation = useSelector(selectTranslations)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const [otherOrganizationData, setOtherOrganizationData] = useState<Organization>()
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const organization = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)

    useEffect(() => {
        if (otherOrgId) {
            getOrganizationData(otherOrgId!).then(response => {
                if (response.status === 200) {
                    setIsYourOrg(response.data.is_admin)
                    if (response.data.is_admin) {
                        dispatch(setRoleStatusData(true))
                        dispatch(setRoleData(RoleType.ORGANIZATION))
                        SessionService.setItem(StorageKey.roles, RoleType.ORGANIZATION)
                        SessionService.setItem(StorageKey.organizationId, otherOrgId!)
                        response.data.organization.tutorial_progress = response.data.tutorial_progress
                        dispatch(setOrganizationData(response.data.organization))
                    } else {
                        setOtherOrganizationData(response.data.organization)
                    }
                }
            })
        } else if (userDataState.id && id) {
            getOrganizationData(id!).then(response => {
                if (response.status === 200) {
                    setIsYourOrg(response.data.is_admin)
                    if (response.data.is_admin) {
                        dispatch(setRoleStatusData(true))
                        dispatch(setRoleData(RoleType.ORGANIZATION))
                        SessionService.setItem(StorageKey.roles, RoleType.ORGANIZATION)
                        SessionService.setItem(StorageKey.organizationId, id!)
                    } else {
                        setOtherOrganizationData(response.data.organization)
                    }
                }
            })
        }
    }, [otherOrgId, id, dispatch, userDataState.id])
    return (
        <BackgroundWrapper>
            <Header />
            <OrganizationProfileCoverPhoto isYourOrganization={isYourOrg} otherOrganization={otherOrganizationData!} />
            <MainLayout
                leftPanelFirstComponent={role === RoleType.ORGANIZATION ? <IndividualProfileCard /> : <OrganizationProfileCard />}
                leftPanelSecondComponent={
                    role === RoleType.ORGANIZATION ? <RoomDropdown type={RoleType.ORGANIZATION} /> : <RoomDropdown type={RoleType.USER} />
                }
                leftPanelThirdComponent={
                    role === RoleType.ORGANIZATION ? (
                        organization.subscription_type === SubscriptionType.free ? (
                            <GoPremiumCard shape="tall" text={translation.upgradeYourProfile} />
                        ) : null
                    ) : null
                }
                leftPanelForthComponent={translation.newestOrganizations}
                leftPanelFifthComponent={<NewestOrganizationsCard />}
                RightPanelFirstComponent={<OrganizationProfilePinboardContainer isYourOrganization={isYourOrg} hasTitle={true} />}
            >
                <div className="organization-profile-employees-screen ">
                    <OrganizationProfileHeader isYourOrganization={isYourOrg} otherOrganization={otherOrganizationData!} />
                    <div className="organization-profile-employees-screen__employees-container">
                        <OrganizationProfileEmployees
                            isExpandedScreen={true}
                            isYourOrganization={isYourOrg}
                            hasSearchCategories={false}
                            hasSearch={true}
                            filterNumber={5000}
                            isEditable={false}
                            hasButton={false}
                            filterTagNumber={3}
                        />
                    </div>
                </div>
            </MainLayout>
            <Footer />
            <ModalGroup />
        </BackgroundWrapper>
    )
}

export default OrganizationProfileEmployeesScreen
