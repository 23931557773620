import React, { MouseEventHandler, useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import "../../../Styles/main.scss"
import { CTALogoIcon, LoadingAnimation, MediumLogoIcon, SendIcon, SmileIcon, SmileyFace } from "./SvgIcons"
import { organizationProfileDefault, profileDefault, roomDefaultPhoto } from "../../../App/GlobaleVariables"
import { RootState } from "../../../App/store"
import { useNavigate, useParams } from "react-router-dom"
import { ConvertStringToHTML, capitalizeFirstLetter, formatDate } from "../../../App/Helpers/Helpers"
import { CallerComponent, CommentType, EditorModifier, PostType, RoleType, RoomType } from "../../../App/enums"
import MessageBody from "./MessageBody"
import Image from "../../Shared/Primitive/Image"
import { setModalData } from "../SharedSlices/ModalSlice"
import { hashtagRegex } from "../../../App/Regex"
import HTTPService from "../../../App/HTTPService"
import { headers } from "../../../App/Axios"
import Button from "../Primitive/Button"

function FeedCTASlider() {
    const translation = useSelector(selectTranslations)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const OnShareNowButtonClick = () => {
        TrackCtaClick('cta-1')
        HTTPService.get(`/search/?query=Edusiia's Lobby`, headers).then(response => {
            if (response.status == 200 && response.data.results.length == 1) {
                let id = response.data.results[0].id
                navigate(`/room/${id}`)
            }
        })
    }

    const OnEditNowButtonClick = () => {
        TrackCtaClick('cta-0')
        navigate('/user-profile')
    }

    const OnDiscoverNowButtonClick = () => {
        TrackCtaClick('cta-2')
        navigate('/rooms')
    }

    const OnDiscoverNewMembersButtonClick = () => {
        TrackCtaClick('cta-3')
        navigate('/newest-members')
    }

    const OnEditHashtagButtonClick = () => {
        TrackCtaClick('cta-4')
        navigate('/dashboard?settings=profile')
    }

    const TrackCtaClick = async (slide: string) => {
        try{
            const response = await HTTPService.post("/cta-log/", {slide : slide})
        }catch{
            //TBD
        }
    }

    let ctaBoxes = [
        {
            instance: <FeedCTAItem buttonText={translation.editNow} text="Fülle dein Profil weiter aus, damit dich andere besser finden und kontaktieren können." OnButtonClick={OnEditNowButtonClick} key="cta-0" />,
            enabled: userDataState.edusiia_reasons && userDataState.description ? false : true
        },
        {
            instance: <FeedCTAItem buttonText={translation.shareNow} text="Was ist beruflich gerade deine größte Herausforderung? Teile sie in der edusiia Lobby, um schneller an eine Lösung zu kommen." OnButtonClick={OnShareNowButtonClick} key="cta-1" />,
            enabled: true
        },
        {
            instance: <FeedCTAItem buttonText={translation.discoverNow} text="Hast du schon geschaut, welche Diskussionsräume sonst noch bei edusiia spannend für dich sein könnten?" OnButtonClick={OnDiscoverNowButtonClick} key="cta-2" />,
            enabled: true
        },
        {
            instance: <FeedCTAItem buttonText={translation.letsstart} text="edusiia ist DER digitale Ort für Vernetzung in der Bildung. Schicke gleich heute noch eine Chat-Nachricht an eine Person, die du interessant findest." OnButtonClick={OnDiscoverNewMembersButtonClick} key="cta-3" />,
            enabled: true
        },
        {
            instance: <FeedCTAItem buttonText={translation.fillNow} text="Mit Hashtags erfahren andere Mitglieder auf einen Blick mehr zu dir. Was macht dich und deine Arbeit aus?" OnButtonClick={OnEditHashtagButtonClick} key="cta-4" />,
            enabled: userDataState.hashtags != null && userDataState.hashtags.length > 0 ? false : true
        }
    ]

    let enabledBoxes = ctaBoxes.filter(b => b.enabled)

    const [RandomDisplay,SetRandomDisplay] = useState(0);

    useEffect(() => {
        enabledBoxes = ctaBoxes.filter(b => b.enabled)
        SetRandomDisplay( Math.floor(Math.random() * enabledBoxes.length));
    },[])

    return enabledBoxes[RandomDisplay].instance
}

function FeedCTAItem(props: { text: string, buttonText: string, OnButtonClick: MouseEventHandler<HTMLButtonElement> }) {
    const translation = useSelector(selectTranslations)

    return (
        <>
            <div className="feed-item " key="feed-cta">
                <div className="feed-item__card feed-item__cta-box">
                    <div className="feed-item--padding-1">
                        <div>
                            <div className="feed-item__text-container">
                                <div className="feed-item__cta-text">
                                    {props.text}
                                </div>
                                <div className="feed-item__cta-button-container">
                                    <Button
                                        onClick={props.OnButtonClick}
                                        className="button button--border-radius-09 button--color-gold button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-medium button--font-weight-400 feed-item__cta-button"
                                    >
                                        {props.buttonText}
                                    </Button>
                                </div>
                                <div className="feed-item__cta-edusiia-img">
                                    <div>
                                    <CTALogoIcon  />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FeedCTASlider

export { FeedCTAItem, FeedCTASlider }
