import { NavigateFunction } from "react-router-dom"
import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import StorageService from "../../../App/StorageService"
import { useDispatch, useSelector } from "react-redux"
import { setUserData } from "../../Shared/SharedSlices/UserDataSlice"
import { RootState } from "../../../App/store"
import { User } from "../../../Types/User"

export const updateUserTutorialProgressRequest = (user: User, data: { tutorial_progress: number }) => {
    return HTTPService.patch(`/user/${user.id}/profile/`, data, headers)
}