import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { GreyVectorDown, EditProfileSmallIcon, CloseIcon, ThreeDotsIcon, LeaveIcon, PendingIcon, JoinRoomIcon } from "../../Shared/Components/SvgIcons"
import Image from "../../Shared/Primitive/Image"
import RoomsDropdown from "../Components/RoomsDropdown"
import RoomMobileMenu from "./RoomMobileMenu"
import { RootState } from "../../../App/store"
import { roomDefaultPhoto } from "../../../App/GlobaleVariables"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { JoinStatus, NotificationResponse, PendingType, RoleType, RoomType, SubscriptionType } from "../../../App/enums"
import Button from "../../Shared/Primitive/Button"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { useNavigate, useParams } from "react-router-dom"
import { onJoinRoomRequest, onOrganizationJoinRoomRequest } from "../RoomServiceRequests/JoinRoomServiceRequest"
import { onRespondToInvitation } from "../../Notifications/UserNotificationServiceRequest"
import { capitalizeFirstLetter } from "../../../App/Helpers/Helpers"
import { onOrganizationRespondToInvitation } from "../../Notifications/OrganizationNotificationServiceRequest"
import TutorialBubble from "../../Shared/Components/TutorialBubble"

function RoomHeader(props: { roomType: string }) {
    const [isRoomsDropdownOpen, setIsRoomsDropdownOpen] = useState(false)
    const organization = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    let RoomsDropdownRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
    let RoomMobileMenuRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
    const translation = useSelector(selectTranslations)
    const [isMobileMenuOpen, setIsMobileDropdownMenuOpen] = useState(false)
    const roomDataState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const isMemberInRoom = useSelector((state: RootState) => state.MemberInRoomStatusSlice.MemberInRoomStatusSlice.isMember)
    const PendingTypeState = useSelector((state: RootState) => state.MemberInRoomStatusSlice.PendingTypeSlice)
    const isRoomManager = useSelector((state: RootState) => state.roomSlice.roomManagerSlice.room_manager)
    const isRoomOwner = useSelector((state: RootState) => state.roomSlice.roomOwnerSlice.room_owner)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { roomId } = useParams()
    const RoomRequestPermissions = roomDataState.type === RoomType.Private ? roomDataState?.room_permissions[0].allow_requests : true
    const hasPrivilegedPermission = role === RoleType.USER ? isRoomManager : isRoomOwner

    useEffect(() => {
        const onMouseDownHandler = (event: Event) => {
            if (isRoomsDropdownOpen && RoomsDropdownRef.current && !RoomsDropdownRef.current.contains(event.target as HTMLElement)) {
                setIsRoomsDropdownOpen(false)
            }
            if (isMobileMenuOpen && RoomMobileMenuRef.current && !RoomMobileMenuRef.current.contains(event.target as HTMLElement)) {
                setIsMobileDropdownMenuOpen(false)
            }
        }
        document.addEventListener("mousedown", onMouseDownHandler)
    })

    const onEditRoomModalOpen = () => {
        dispatch(setModalData({ open: true, editRoom: true }))
    }

    const onRoomAction = () => {
        switch (role) {
            case RoleType.USER:
                if (isMemberInRoom === JoinStatus.MEMBER) {
                    dispatch(setModalData({ open: true, leaveRoomModal: true }))
                }
                if (isMemberInRoom === JoinStatus.NON_MEMBER) {
                    if (roomDataState.join_question.length > 0 && roomDataState.type == "private") {
                        dispatch(setModalData({
                            open: true,
                            joinPrivateRoomModal: {
                                status: true,
                                question:
                                    roomDataState.join_question,
                                roomId: roomDataState.id,
                                organizationName: roomDataState.organization?.name!,
                                roomType: roomDataState.type
                            }
                        }))
                    } else {
                        dispatch(onJoinRoomRequest(parseInt(roomId!), roomDataState.type))
                    }
                }
                break
            case RoleType.ORGANIZATION:
                if (isMemberInRoom === JoinStatus.MEMBER) {
                    dispatch(setModalData({ open: true, leaveRoomModal: true }))
                }
                if (isMemberInRoom === JoinStatus.NON_MEMBER) {
                    if (roomDataState.join_question.length > 0 && roomDataState.type == "private") {
                        dispatch(setModalData({
                            open: true,
                            joinPrivateRoomModal: {
                                status: true,
                                question:
                                    roomDataState.join_question,
                                roomId: roomDataState.id,
                                organizationName: roomDataState.organization?.name!,
                                roomType: roomDataState.type,
                                orgId: organization.id!
                            }
                        }))
                    } else {
                        dispatch(onOrganizationJoinRoomRequest(organization.id!, parseInt(roomId!)))
                    }
                }
                break
            default:
                break
        }
    }

    const onAccept = () => {
        switch (role) {
            case RoleType.USER:
                dispatch(onRespondToInvitation(PendingTypeState.notification_id, NotificationResponse.accepted, parseInt(roomId!), navigate))
                break
            case RoleType.ORGANIZATION:
                dispatch(
                    onOrganizationRespondToInvitation(
                        PendingTypeState.notification_id,
                        NotificationResponse.accepted,
                        parseInt(roomId!),
                        organization.id?.toString()!,
                        navigate
                    )
                )
                break
            default:
                break
        }
    }

    const onDecline = () => {
        switch (role) {
            case RoleType.USER:
                dispatch(onRespondToInvitation(PendingTypeState.notification_id, NotificationResponse.declined, parseInt(roomId!), navigate))
                window.location.reload()
                break
            case RoleType.ORGANIZATION:
                dispatch(
                    onOrganizationRespondToInvitation(
                        PendingTypeState.notification_id,
                        NotificationResponse.declined,
                        parseInt(roomId!),
                        organization.id?.toString()!,
                        navigate
                    )
                )
                window.location.reload()
                break
            default:
                break
        }
    }

    return (
        <div className="room-header-container">
            <div className="room-header">
                <>
                    {!roomDataState?.is_active && (
                        <div className="room-header__notice">
                            <div>
                                {translation.inactiveRoomNotice_1}{" "}
                                <a className="" href="https://edusiia.com/faq/#funktionen" target="_blank" rel="noreferrer">
                                    FAQ
                                </a>
                                {translation.inactiveRoomNotice_2}
                            </div>
                        </div>
                    )}

                    <div className="room-header__room-content">
                        <div className="room-header__profile-photo">
                            <Image
                                src={roomDataState?.photo || roomDefaultPhoto}
                                alt="room"
                                className="image__profile image__profile--size-small image__profile--fit"
                            />
                        </div>
                        {hasPrivilegedPermission && (
                            <div ref={RoomsDropdownRef} className="room-header__rooms-dropdown-container">
                                <div
                                    onClick={() => setIsRoomsDropdownOpen(!isRoomsDropdownOpen)}
                                    className={`room-header__arrow-icon ${isRoomsDropdownOpen && "room-header__arrow-icon--selected"}`}
                                >
                                    <GreyVectorDown />
                                </div>
                                {isRoomsDropdownOpen && (
                                    <RoomsDropdown
                                        isPremiumOrganization={organization.subscription_type === SubscriptionType.free ? false : true}
                                        isOpen={setIsRoomsDropdownOpen}
                                    />
                                )}
                            </div>
                        )}

                        {isRoomsDropdownOpen && <div className="room-header__dropdown-overlay room-header__dropdown-overlay--breakpoint-550" />}
                        <div className="room-header__name-container">
                            <div className={`room-header__name ${!hasPrivilegedPermission && "room-header__name--margin-left-75"}`}>{roomDataState?.name}</div>
                        </div>
                        <div
                            className={`room-header__type ${props.roomType === capitalizeFirstLetter(translation.public)
                                    ? "room-header__type--background-color-blue"
                                    : "room-header__type--background-color-gold"
                                }`}
                        >
                            {props.roomType}
                        </div>
                        <TutorialBubble text={translation.tutorial_org_room_types} modifier="top speechbubble__orga-room-type" visible={isRoomOwner && organization.tutorial_progress == 2} hasNext={true} />
                        {hasPrivilegedPermission && (
                            <>
                                <div className="room-header__edit-icon" onClick={onEditRoomModalOpen}>
                                    <EditProfileSmallIcon />
                                </div>
                                <TutorialBubble text={translation.tutorial_org_room_edit} modifier="left speechbubble__orga-room-edit" visible={isRoomOwner && organization.tutorial_progress == 3} hasNext={true} />
                            </>
                        )}
                    </div>

                    {!hasPrivilegedPermission && roomDataState.type ? (
                        <>
                            {isMemberInRoom === JoinStatus.MEMBER && (
                                <div className="room-header__button  room-header__button--margin-right-3">
                                    <Button
                                        onClick={onRoomAction}
                                        className="button button--border-radius-09 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-medium button--font-weight-400"
                                    >
                                        <div className="room-header__button-icon">
                                            <LeaveIcon />
                                        </div>
                                        {translation.leaveRoom}
                                    </Button>
                                </div>
                            )}
                            {roomDataState.is_active &&
                                isMemberInRoom === JoinStatus.NON_MEMBER &&
                                RoomRequestPermissions && (
                                    <div className="room-header__button   room-header__button--margin-right-3">
                                        <Button
                                            onClick={onRoomAction}
                                            className="button button--border-radius-09 button--color-gold button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-medium button--font-weight-400"
                                        >
                                            <div className="room-header__button-icon">
                                                <JoinRoomIcon />
                                            </div>
                                            {translation.joinRoom}
                                        </Button>
                                    </div>
                                )}
                            {roomDataState.is_active && isMemberInRoom === JoinStatus.PENDING && PendingTypeState.type === PendingType.request && (
                                <div className="room-header__button   room-header__button--margin-right-3">
                                    <Button className="button button--border-radius-09 button--color-blue button--text-color-white button--font-size-medium button--font-weight-400">
                                        <div className="room-header__button-icon">
                                            <PendingIcon />
                                        </div>
                                        {translation.pending}
                                    </Button>
                                </div>
                            )}
                            {roomDataState.is_active && isMemberInRoom === JoinStatus.PENDING && PendingTypeState.type === PendingType.invite && (
                                <div className="room-header__button-container">
                                    <div className="edit-post__button">
                                        <Button
                                            onClick={onAccept}
                                            className="button button--border-radius-05 button--color-gold button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400"
                                        >
                                            {translation.accept}
                                        </Button>
                                    </div>
                                    <div className="edit-post__button">
                                        <Button
                                            onClick={onDecline}
                                            className="button button--border-radius-05 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400"
                                        >
                                            {translation.decline}
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : null}

                    <div className="room-header__menu" ref={RoomMobileMenuRef}>
                        <div className="room-header__menu-icon" onClick={() => setIsMobileDropdownMenuOpen(!isMobileMenuOpen)}>
                            {isMobileMenuOpen ? <CloseIcon /> : <ThreeDotsIcon />}
                        </div>
                        {isMobileMenuOpen && <RoomMobileMenu />}
                    </div>

                    {isMobileMenuOpen && <div className="room-header__dropdown-overlay room-header__dropdown-overlay--breakpoint-768" />}
                </>
            </div>
            {!hasPrivilegedPermission && roomDataState.type ? (
                <>
                    <div
                        onClick={onRoomAction}
                        className={`room-header__lower-panel room-header__lower-panel--${isMemberInRoom === JoinStatus.MEMBER
                                ? "display-none"
                                : isMemberInRoom === JoinStatus.NON_MEMBER
                                    ? "color-gold"
                                    : isMemberInRoom === JoinStatus.PENDING
                                        ? PendingTypeState.type === PendingType.request
                                            ? "color-blue"
                                            : PendingTypeState.type === PendingType.invite && "display-none"
                                        : null
                            } ${isMobileMenuOpen && `room-header__lower-panel--zindex-2`}`}
                    >
                        {isMemberInRoom === JoinStatus.NON_MEMBER && RoomRequestPermissions && (
                            <>
                                <div className="room-header__icon">
                                    <JoinRoomIcon />
                                </div>
                                {translation.joinRoom}
                            </>
                        )}

                        {isMemberInRoom === JoinStatus.PENDING && (
                            <>
                                <div className="room-header__icon">
                                    <PendingIcon />
                                </div>
                                {translation.pending}
                            </>
                        )}
                    </div>
                    {isMemberInRoom === JoinStatus.PENDING && PendingTypeState.type === PendingType.invite && (
                        <div className="room-header__small-button-container">
                            <div className="room-header__small-button room-header__small-button--margin-right">
                                <Button
                                    onClick={onAccept}
                                    className="button button--border-radius-05 button--color-gold button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400"
                                >
                                    {translation.accept}
                                </Button>
                            </div>
                            <div className="room-header__small-button">
                                <Button
                                    onClick={onDecline}
                                    className="button button--border-radius-05 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400"
                                >
                                    {translation.decline}
                                </Button>
                            </div>
                        </div>
                    )}
                </>
            ) : null}
        </div>
    )
}
export default RoomHeader
