import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { setModalData } from "../SharedSlices/ModalSlice"
import { setVideoVisible } from "../SharedSlices/ExplanationVideoSlice"
import Card from "../Primitive/Card"
import "../../../Styles/main.scss"
import { QuestionIcon } from "../Components/SvgIcons"

function HeaderHelpMenu() {
    const dispatch = useDispatch()
    const translation = useSelector(selectTranslations)
    const [isQuestionMenuOpen, setIsQuestionMenuOpen] = useState(false)
    let questionMenuRef = React.useRef() as React.MutableRefObject<HTMLInputElement>

    const handleStartTutorial = () => {
        dispatch(dispatch(setModalData({ open: true, showTutorial: true })))
    }

    useEffect(() => {
        const onMouseDownHandler = (event: Event) => {
            if (isQuestionMenuOpen && questionMenuRef.current && !questionMenuRef.current.contains(event.target as HTMLElement)) {
                setIsQuestionMenuOpen(false)
            }
        }
        document.addEventListener("mousedown", onMouseDownHandler)
    })

    return (
        <div className="header__menu-question" ref={questionMenuRef}>
            <div className="header__icon" onClick={() => setIsQuestionMenuOpen(!isQuestionMenuOpen)}>
                <QuestionIcon />
            </div>
            {isQuestionMenuOpen && (
                <div className="header-question-menu">
                    <Card>
                        <div className="header-question-menu__card">
                            <div className="header-question-menu__item header-question-menu__item--border-bottom-3" onClick={handleStartTutorial}>
                                {translation.onboarding}
                            </div>

                            <a
                                href="https://edusiia.com/faq/"
                                target="_blank"
                                rel="noreferrer"
                                className="header-question-menu__item header-question-menu__item--border-bottom-3"
                            >
                                {" "}
                                {translation.questionAndAnswers}
                            </a>
                            <a
                                href="https://edusiia.com/kontakt/"
                                target="_blank"
                                rel="noreferrer"
                                className="header-question-menu__item header-question-menu__item"
                            >
                                {" "}
                                {translation.contact}
                            </a>
                        </div>
                    </Card>
                </div>
            )}{" "}
        </div>
    )
}

export default HeaderHelpMenu
