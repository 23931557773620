import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import "../../../../Styles/main.scss"
import Image from "../../../Shared/Primitive/Image"
import { AddBlueIcon, CloseIcon, TooltipQuestionmark, TransferIcon } from "../../../Shared/Components/SvgIcons"
import UserCard from "../../../Shared/Components/UserCard"
import { useNavigate, useParams } from "react-router-dom"
import { RootState } from "../../../../App/store"
import { RoleType } from "../../../../App/enums"
import { profileDefault } from "../../../../App/GlobaleVariables"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import Tooltip from "../../../Shared/Primitive/Tooltip"
import TutorialBubble from "../../../Shared/Components/TutorialBubble"

export type AdminInfoType = {
    first_name: string
    id: number
    last_name: string
    profile_photo: {
        id: number
        url_path: string
    }
    role_id: number
}

function OrganizationProfileAdminSection(props: { isYourOrganization: boolean; admins: AdminInfoType[]; isOwner: boolean }) {
    const [selectedId, setSelectedId] = useState<number>(0)
    const translation = useSelector(selectTranslations)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const organizationDataState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    let dropdownRef = React.useRef() as React.MutableRefObject<HTMLInputElement>

    useEffect(() => {
        const onMouseDownHandler = (event: Event) => {
            if (selectedId > 0 && dropdownRef.current && !dropdownRef.current.contains(event.target as HTMLElement)) {
                setSelectedId(0)
            }
        }
        document.addEventListener("mousedown", onMouseDownHandler)
    })

    const onNavigationToUserProfile = (userId: number) => {
        switch (role) {
            case RoleType.USER:
                if (userDataState.id === userId) {
                    const userPath = `/user-profile`
                    navigate(userPath)
                    window.scrollTo(0, 0)
                } else {
                    const userPath = `/user/${userId}`
                    navigate(userPath)
                    window.scrollTo(0, 0)
                }

                break
            case RoleType.ORGANIZATION:
                if (userDataState.id === userId) {
                    const organizationPath = `/user-profile`
                    navigate(organizationPath)
                    window.scrollTo(0, 0)
                } else {
                    const organizationPath = `/user/${userId}/${id}`
                    navigate(organizationPath)
                    window.scrollTo(0, 0)
                }

                break
            default:
                break
        }
    }

    const onPlusIconClick = () => {
        dispatch(setModalData({ open: true, addAdminModal: true }))
    }

    const onCloseIconClick = (adminId: AdminInfoType) => {
        dispatch(setModalData({ open: true, removeAdminModal: { status: true, admin: adminId, removedAdmin: false } }))
    }

    const onTransferOwnershipClick = () => {
        dispatch(setModalData({ open: true, transferOwnership: { status: true, admin: props.admins, is_owner: props.isOwner } }))
    }

    return (
        <div className="organization-profile-admin-section">
            {role == RoleType.ORGANIZATION && props.isYourOrganization && (
                <TutorialBubble text={translation.tutorial_org_admin_view} modifier="left speechbubble__admin" visible={organizationDataState.tutorial_progress == 9} hasNext={false} />
            )}
            <div className="organization-profile-admin-section__title">{translation.Admin}</div>

            <div className="organization-profile-admin-section__content">
                <div className="organization-profile-admin-section__admin-container">
                    {props.admins.map((item, index) => {
                        const selectedItem = selectedId === item.id

                        return (
                            <div key={index} className="organization-profile-admin-section__admin">
                                <Image
                                    src={item.profile_photo?.url_path || profileDefault}
                                    className="image image__profile image__profile--size-small image__profile--fit"
                                    onClick={() => setSelectedId(item.id)}
                                />
                                {selectedItem && (
                                    <div key={item.id} ref={dropdownRef}>
                                        <UserCard
                                            imageSrc={item.profile_photo?.url_path || profileDefault}
                                            text={item.first_name + " " + item.last_name}
                                            onLeftItemClick={() => onNavigationToUserProfile(item.id)}
                                            onMiddleItemClick={() => onNavigationToUserProfile(item.id)}
                                            profileImageStyle="image image__profile image__profile--fit image__profile--size-small "
                                            userCardStyle={`admin-card-selected`}
                                            leftPanelStyle="left-panel"
                                            middlePanelStyle="middle-panel"
                                            textStyle="text"
                                            textStyleMore25="text"
                                            subtextStyle="subtext"
                                            rightText={
                                                props.isOwner && item.id !== userDataState.id && props.isYourOrganization ? (
                                                    <div className="admin-card-selected__close-icon" onClick={() => onCloseIconClick(item)}>
                                                        <CloseIcon />
                                                    </div>
                                                ) : null
                                            }
                                            rightTextStyle="right-panel"
                                        />
                                    </div>
                                )}
                            </div>
                        )
                    })}
                    {props.isOwner && props.admins.length <= 3 && props.isYourOrganization && (
                        <div className="organization-profile-admin-section__add" onClick={onPlusIconClick}>
                            <AddBlueIcon />
                        </div>
                    )}
                </div>

                {props.isOwner && (
                    <div className="organization-profile-admin-section__transfer-container" onClick={onTransferOwnershipClick}>
                        <TransferIcon />
                        <div className="organization-profile-admin-section__transfer-text-container">
                            <p className="organization-profile-admin-section__transfer-text">{translation.transferOwnership}</p>
                            <Tooltip
                                direction="settings-top"
                                content={
                                    <p>
                                        <br />
                                        {translation.transferTooltip2}
                                        <br /> {translation.transferTooltip3}
                                        <br />
                                        {translation.transferTooltip4} <br />
                                        {translation.transferTooltip5}
                                    </p>
                                }
                            >
                                <TooltipQuestionmark />
                            </Tooltip>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default OrganizationProfileAdminSection
