import React, { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import "../../../Styles/main.scss"
import { Room } from "../../../Types/Room"
import UserCard from "./UserCard"
import { SendIcon, SmileIcon, SmileyFace } from "./SvgIcons"
import { organizationProfileDefault, profileDefault, roomDefaultPhoto } from "../../../App/GlobaleVariables"
import { RootState } from "../../../App/store"
import { useNavigate, useParams } from "react-router-dom"
import useOnScreen, { ConvertStringToHTML, capitalizeFirstLetter, formatDate } from "../../../App/Helpers/Helpers"
import { CallerComponent, CommentType, EditorModifier, PostType, RoleType, RoomType } from "../../../App/enums"
import { convertTagElement } from "./Editor/convertTagElement"
import MessageBody from "./MessageBody"
import { MentionedUsersType, RoomPosts } from "../../../Types/RoomPosts"
import Image from "../../Shared/Primitive/Image"
import { setModalData } from "../SharedSlices/ModalSlice"
import Reaction from "./Reactions"
import Comments from "./Comments"
import CharacterCounter from "./CharacterCounter"
import EditorTextArea from "./Editor/EditorTextArea"
import { Editor as TinyMCEEditor } from "tinymce"
import Picker from "emoji-picker-react"
import { postSeenRequest } from "../../Room/RoomPost/RoomPostRequests"
import { hashtagRegex } from "../../../App/Regex"
import { setPostContentErrorState, setRoomPostsData } from "../../Room/RoomPost/RoomPostSlice"
import HTTPService from "../../../App/HTTPService"
import { headers } from "../../../App/Axios"
import FeedComments from "./FeedComments"
import { relative } from "path"
import HoverBox from "./HoverBox"

function FeedItem(props: { type: RoleType.USER | RoleType.ORGANIZATION; post: RoomPosts; isLoading: boolean }) {
    const translation = useSelector(selectTranslations)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const posts = useSelector((state: RootState) => state.RoomPostsSlice.roomPostsSlice.posts)
    const [isEmojiSelectorOpen, setIsEmojiSelectorOpen] = useState(false)
    let emojiRef = useRef() as React.MutableRefObject<HTMLInputElement>
    const [isCommentEmojiSelectorOpen, setCommentIsEmojiSelectorOpen] = useState(false)
    let commentEmojiRef = useRef() as React.MutableRefObject<HTMLInputElement>
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [isCommentSectionCollapsed, setIsCommentSectionCollapsed] = React.useState(true)
    const editorRef = useRef<TinyMCEEditor | null>(null)
    const [inputState, setInputState] = useState<string>("")
    const [content, setContent] = useState<string>("")
    const contentMaxLength = 2000
    const ref = useRef() as React.MutableRefObject<HTMLDivElement>
    const isVisible = useOnScreen(ref)
    const [seenRequestSent,SetSeenRequestSent] = useState(false)

    const onRoomNameClick = (roomId: number, organizationId: number) => {
        switch (props.type) {
            case RoleType.USER:
                navigate(`/room/${roomId}`)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                navigate(`/room/${organizationId}/${roomId}`)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    useEffect(() => {
        if(isVisible && !seenRequestSent){
            //mark post as seen
            try{
                postSeenRequest(props.post.id,'user',userDataState.id)
                SetSeenRequestSent(true)
            }catch{
                SetSeenRequestSent(false)
            }
        }
    },[isVisible])

    const onRoomNameMouseOver = () => {
        //TODO
    }

    const onRoomNameMouseLeave = () => {
        //TODO
    }

    const onCommentClick = () => {
        isCommentSectionCollapsed ? setIsCommentSectionCollapsed(false) : setIsCommentSectionCollapsed(true)
    }

    const onOrganizationNameClick = (organizationId: number) => {
        switch (role) {
            case RoleType.USER:
                navigate(`/organization-profile/${organizationId}/`)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                navigate(`/organization-profile/${organizationId}/`)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    const onNameClick = (id: number, type: string, organizationId: number) => {
        switch (type) {
            case RoleType.USER.toLowerCase():
                if (userDataState.id === id) {
                    navigate(`/user-profile`)
                    window.scrollTo(0, 0)
                } else {
                    navigate(`/user/${id}`)
                    window.scrollTo(0, 0)
                }
                break
            case RoleType.ORGANIZATION.toLowerCase():
                navigate(`/organization-profile/${id!}`)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    const openReactionsModal = (
        reaction_info: {
            count: number
            name: string
            users: { id: number; first_name?: string; last_name?: string; profile_photo?: { id: number; url_path: string } }[]
        }[]
    ) => {
        dispatch(setModalData({ open: true, reactionsModal: { status: true, reaction: reaction_info } }))
    }

    const onEmojiClick = (event: { preventDefault: () => void }, emojiObject: any) => {
        event.preventDefault()
        if (editorRef.current) {
            editorRef.current!.insertContent(emojiObject.emoji)
        }
    }

    const onCommentEmojiClick = (event: { preventDefault: () => void }, emojiObject: any) => {
        event.preventDefault()
        if (editorRef.current) {
            editorRef.current!.insertContent(emojiObject.emoji)
        }
    }

    const postRequest = (data: FormData) => {
        return HTTPService.post(`/${PostType.comment}/`, data, headers)
    }

    const onPostSubmit = async () => {
        const objectId = userDataState.id
        const mentionedUsersArray: MentionedUsersType[] = []
        let modifiedContent = ""
        if (editorRef.current!) {
            const htmlContent = ConvertStringToHTML(editorRef!.current!.getContent({ format: "raw" }))
            const tagSpans = htmlContent.querySelectorAll("[data-type]")

            tagSpans.forEach((span, index) => {
                span.innerHTML = "@tagged"
                mentionedUsersArray.splice(index, 0, {
                    tagged_user: span.getAttribute("data-type") === RoleType.USER.toLocaleLowerCase() ? parseInt(span.id) : null,
                    tagged_org: span.getAttribute("data-type") === RoleType.ORGANIZATION.toLocaleLowerCase() ? parseInt(span.id) : null,
                })
                modifiedContent = htmlContent.outerHTML.replaceAll("<body>", "").replaceAll("</body>", "").replace(hashtagRegex, "$1")
            })

            if (mentionedUsersArray.length >= 1 || editorRef.current!.getContent({ format: "text" }).length > 0) {
                const formData = new FormData()

                formData.append(
                    "content",
                    modifiedContent.length > 0 ? modifiedContent : editorRef!.current!.getContent({ format: "raw" }).replace(hashtagRegex, "$1")
                )
                formData.append("content_type", role.toLowerCase())
                formData.append("object_id", objectId?.toString()!)
                formData.append("tags", JSON.stringify(mentionedUsersArray))

                formData.append("post", props.post.id.toString())

                try {
                    const response = await postRequest(formData)
                    if (response.status === 201) {

                        let updatedPosts = [...posts]
                        const idx = posts.findIndex(p => p.id == props.post.id)
                        updatedPosts[idx] = { ...posts[idx], post_comments: [...posts[idx].post_comments, response.data], total_commments: posts[idx].total_commments + 1 }
                        dispatch(setRoomPostsData(updatedPosts))
                        editorRef!.current.setContent("")
                    }
                } catch (error: any) {
                    if (error.response?.status === 400) {
                        dispatch(setPostContentErrorState(error.response.data.content[0]))
                    }
                    console.log(error)
                }
            }
        }
    }

    const onPostClick = (roomId: number, postId: number) => {
        setIsEmojiSelectorOpen(false)
        navigate(`/room-post/${roomId}/${postId}`)
        window.scrollTo(0, 0)
    }
    const mentionedUsers = document.querySelectorAll("[data-type]")
    convertTagElement(mentionedUsers, props.post.tags!, role, id!, userDataState.id)
    if (props.post.room_instance == undefined) {
        return <></>
    }
    return (
        <>
            <div className="feed-item" key={props.post.id} ref={ref}>
                {!props.post.room_joined && (
                    <div className="feed-item-suggested-content">{translation.maybeInteresing}</div>
                )}
                <div className="feed-item__card">
                    <div className="feed-item--padding-1">
                        <UserCard
                            imageSrc={
                                props.post?.content_type === RoleType.USER.toLowerCase()
                                    ? props.post.content_object?.profile_photo?.url_path || profileDefault
                                    : props.post.content_object?.photo?.url_path || organizationProfileDefault
                            }
                            text={
                                <div className="feed-item__post-info">
                                    <span onClick={
                                        props.post.content_object === null
                                            ? undefined
                                            : () => onNameClick(props.post.content_object?.id!, props.post.content_type, parseInt(id!))
                                    }>
                                        {props.post?.content_type === RoleType.ORGANIZATION.toLowerCase()
                                            ? props.post.content_object === null
                                                ? translation.deletedOrganization
                                                : props.post.content_object?.name
                                            : props.post.content_object === null
                                                ? translation.deletedUser
                                                : props.post.content_object?.first_name + " " + props.post.content_object?.last_name}</span>

                                    <br /><span> in </span> <span onClick={() => onRoomNameClick(props.post.room_instance!.id,0)} onMouseOver={onRoomNameMouseOver} onMouseLeave={onRoomNameMouseLeave} className="feed-item-room-card-small__text">{props.post.room_name}
                                    <HoverBox room={props.post.room_instance} />
                                    </span>
                                    
                                </div>
                            }
                            rightText={
                                <>
                                    <div className="feed-item-profile-card__time">{formatDate(props.post.date, "HH:mm ")!}</div>
                                    <div className="feed-item-profile-card__date">{formatDate(props.post.date, "dd MMM")!} </div>
                                </>
                            }
                            userCardStyle={"feed-item-profile-card"}
                            leftPanelStyle={`left-panel   ${props.post.content_object === null && "feed-item-profile-card__left-panel--deleted"}`}
                            middlePanelStyle={`middle-panel`}
                            rightPanelStyle={"right-panel"}
                            profileImageStyle={"image image__profile image__profile--fit image__profile--size-small"}
                            textStyle={`text ${props.post.content_object === null && "feed-item-profile-card__text--deleted"}`}
                            rightTextStyle={"right-text"}
                            onLeftItemClick={
                                props.post.content_object === null
                                    ? undefined
                                    : () => onNameClick(props.post.content_object?.id!, props.post.content_type, parseInt(id!))
                            }
                            textStyleMore25={`textMore25 ${props.post.content_object === null && "feed-item-profile-card__textMore25--deleted"}`}
                        />
                        <div>
                            <div className="feed-item__text-container-no-border">
                                <div className="feed-item__text">
                                    {
                                        <MessageBody
                                            showPreview={false}
                                            text={props.post.content}
                                            caller={CallerComponent.feed}
                                            showRemovePreviewIcon={false}
                                            showCollapsed={true}
                                            hashtags={props.post.hashtags}
                                            isCollapsed={isCollapsed}
                                            toggleCollapse={() => setIsCollapsed(!isCollapsed)}
                                            onNavigationToExpandedPost={() => onPostClick(props.post.room, props.post.id)}
                                        />
                                    }
                                </div>
                            </div>
                            <div className="feed-item__interaction-container">
                                <div className="feed-item__reactions-container">
                                    {props.post.reaction_info?.map((reaction, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={`feed-item__reaction ${index === 2 && "feed-item__reaction--no-margin"}`}
                                                onClick={() => openReactionsModal(props.post.reaction_info!)}
                                            >
                                                <div className="feed-item__emoji" title={reaction.name}>
                                                    <Image src={`/reactions/${reaction.name}.svg`} className="feed-item__emoji-image" />
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {/* {totalReactionCount! > 0 && (
                                                                <div className={`feed-item__reaction-count `}>{props.post.reaction_info.c}</div>
                                                            )} */}

                                    {props.post.room_instance?.is_active && (
                                        <>
                                            <div
                                                onClick={() => {
                                                    setIsEmojiSelectorOpen(!isEmojiSelectorOpen)
                                                }}
                                                className="feed-item__add-emoji-icon"
                                            >
                                                <SmileyFace />
                                            </div>
                                            {isEmojiSelectorOpen && (
                                                <div ref={emojiRef} className="feed-item__emoji-picker">
                                                    <Reaction
                                                        caller={CallerComponent.post}
                                                        callerId={props.post.id}
                                                        postType="posts"
                                                        open={isEmojiSelectorOpen}
                                                        setIsEmojiSelectorOpen={setIsEmojiSelectorOpen}
                                                        isFeed={true}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>

                                <div className="feed-item-profile-card__comments" onClick={() => onCommentClick()}>
                                    {props.post.total_commments} {props.post.total_commments == 1 ? translation.comment : translation.comments}
                                </div>
                            </div>
                            {/* <UserCard
                                imageSrc={props.post.room_instance?.photo || roomDefaultPhoto}
                                subtext={
                                    <p
                                        className="feed-item-room-card-small__overflow"
                                        title={props.post.room_instance?.organization?.name}
                                        onClick={() => onOrganizationNameClick(props.post.room_instance?.organization?.id!)}
                                    >
                                        {props.post.room_instance?.organization?.name!}
                                    </p>
                                }
                                rightText={capitalizeFirstLetter(props.post.room_instance?.type === RoomType.Private ? translation.private : translation.public)}
                                userCardStyle={"feed-item-room-card-small"}
                                leftPanelStyle={"left-panel"}
                                middlePanelStyle={"middle-panel"}
                                rightPanelStyle={"right-panel"}
                                profileImageStyle={"image image__profile image__profile--fit image__profile--size-small"}
                                //textStyle={`text ${props.post.content_object === null && "feed-item-profile-card__text--deleted"}`}
                                textStyle={`${props.post.room_instance?.name.length >= 24 ? "textMore25" : "text"}`}
                                subtextStyle={"subtext"}
                                rightTextStyle={`right-text feed-item-room-card__right-text--${props.post.room_instance?.type}`}
                                onLeftItemClick={() => onRoomNameClick(props.post.room_instance!.id, props.post.room_instance?.organization?.id!)}
                                textStyleMore25={`${props.post.room_instance?.name.length >= 24 ? "textMore25" : "text"}`}
                            /> */}
                        </div>

                        <div className={`feed-item-profile-card__comment-section ${isCommentSectionCollapsed && "feed-item-profile-card__comment-section-hidden"}`}>
                            <p className="feed-item-profile-card__comment-title">Kommentare</p>
                            <FeedComments commentsarray={props.post.post_comments!} type={CommentType.post} />

                            <div className="room-layout__right-panel-input-container">
                                <div className="room-layout__photo-text-container">
                                    <EditorTextArea
                                        inputState={inputState}
                                        setInputState={setInputState}
                                        editorRef={editorRef}
                                        editorPlaceholder={translation.addAComment}
                                        setContent={setContent}
                                        editorHeight={62}
                                        modifier={EditorModifier.roomTextArea}
                                        initialEditorValue=""
                                        enterPressed={() => { }}
                                    />
                                </div>
                                <CharacterCounter max={contentMaxLength} current={content.length} modifier="feed" />
                                <div className="room-layout__right-panel-icons-container">
                                    <div onClick={() => setCommentIsEmojiSelectorOpen(!isCommentEmojiSelectorOpen)} className="room-layout__right-panel-icon ">
                                        <SmileIcon />
                                    </div>
                                    {isCommentEmojiSelectorOpen && (
                                        <div ref={commentEmojiRef} className="room-layout__emoji-picker">
                                            <Picker disableSkinTonePicker={false} onEmojiClick={onCommentEmojiClick} />
                                        </div>
                                    )}
                                    {content.length > 0 && content.length < contentMaxLength + 1 ? (
                                        <div className="room-layout__right-panel-icon" onClick={onPostSubmit}>
                                            <SendIcon color="green" />
                                        </div>
                                    ) : (
                                        <div className="room-layout__right-panel-icon room-layout__right-panel-icon--disable">
                                            <SendIcon color="grey" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FeedItem
