import React, { useEffect, useState } from "react"
import HTTPService from "../../../App/HTTPService"
import "../../../Styles/main.scss"
import Card from "../Primitive/Card"
import UserCard from "./UserCard"
import { headers } from "../../../App/Axios"
import { LoadingAnimation } from "./SvgIcons"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../App/store"
import { useNavigate } from "react-router-dom"
import { organizationProfileDefault } from "../../../App/GlobaleVariables"
import { setUserOrganizationsData } from "../SharedSlices/OrganizationsSlice"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import NoOrganizationCard from "./NoOrganizationCard"
import { organizationNotificationGetRequest } from "../../Notifications/OrganizationNotificationServiceRequest"
import { setUserNotifications } from "../../Notifications/NotificationSlice"
import TutorialBubble from "./TutorialBubble"

export const getOrganizationNameRequest = (userId: number) => {
    return HTTPService.get(`/my-organization-list/`, headers)
}
function OrganizationProfileCard() {
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const organizationsDataState = useSelector((state: RootState) => state.organizationsSlice.userOrganizationsSlice.organizations)
    const organizationDataState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const modalStatus = useSelector((state: RootState) => state.modalSlice)
    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const translation = useSelector(selectTranslations)

    useEffect(() => {
        let isMounted = true
        if (userDataState.id) {
            getOrganizationNameRequest(userDataState.id)
                .then(response => {
                    if (isMounted && response.status === 200) {
                        dispatch(setUserOrganizationsData(response.data))
                        setIsLoading(false)
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }
        return () => {
            isMounted = false
        }
    }, [dispatch, userDataState, userDataState.id, modalStatus.postiveOrganziationEmailVerification])

    const onCardClick = (id: number) => {
        navigate(`/organization-dashboard/${id}`)
        organizationNotificationGetRequest(id.toString()).then(response => {
            if (response.status === 200) {
                dispatch(setUserNotifications(response.data))
            }
        })
        window.scrollTo(0, 0)
    }

    const onButtonClick = () => {
        let path = "/my-organizations"
        navigate(path)
        window.scrollTo(0, 0)
    }

    return (
        <>
            {isLoading ? (
                <div className="profile-card__loading-card">
                    <LoadingAnimation />{" "}
                </div>
            ) : (
                <>
                    {organizationsDataState.length > 0 ? (
                        <>
                            <TutorialBubble text={translation.tutorial_user_switch_profile} modifier="left speechbubble__profile-card" visible={userDataState.tutorial_progress == 7} hasNext={true} routeOnNext={`/organization-dashboard/${organizationsDataState[0].id}`} />
                            <div className="profile-card">
                                {organizationsDataState?.slice(0, 3).map(item => {
                                    return (
                                        <div key={item.id} onClick={() => onCardClick(item.id!)}>
                                            <Card>
                                                <div className="profile-card__container">
                                                    <UserCard
                                                        imageSrc={item.photo?.url_path || organizationProfileDefault}
                                                        text={item.name}
                                                        profileImageStyle="image image__profile image__profile--fit image__profile--size-medium "
                                                        userCardStyle={"item"}
                                                        leftPanelStyle={"left-panel"}
                                                        middlePanelStyle={"middle-panel"}
                                                        textStyle={"text"}
                                                        textStyleMore25={"textMore25"}
                                                    />
                                                </div>
                                            </Card>
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    ) : (
                        <>
                            <NoOrganizationCard />
                            <TutorialBubble text={translation.tutorial_user_create_orga} modifier="left speechbubble__profile-card" visible={userDataState.tutorial_progress == 7} hasNext={false} />
                        </>
                    )}

                    {organizationsDataState.length > 3 && (
                        <div onClick={onButtonClick} className="profile-card__button">
                            {translation.seeAllResults}
                        </div>
                    )}
                </>
            )}
        </>
    )
}

export default OrganizationProfileCard
