import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../App/store"
import { updateUserTutorialProgressRequest } from "../../User/UserProfile/UserTutorialProgressRequest"
import { setUserData } from "../SharedSlices/UserDataSlice"
import StorageService from "../../../App/StorageService"
import { useNavigate } from "react-router-dom"
import { RoleType } from "../../../App/enums"
import { PatchOrganizationTutorialProgressRequest } from "../../Organization/OrganizationSettings/OrganizationAccountServiceRequest"
import { setOrganizationData } from "../SharedSlices/OrganizationsSlice"


function TutorialBubble(props: { text: string, modifier: string, visible: boolean, hasNext: boolean, routeOnNext?: string }) {
    const dispatch = useDispatch()
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const orgDataState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const [isVisible, setIsVisible] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        setIsVisible(props.visible)
    }, [props.visible])

    useEffect(() => {
        if(Object.keys(userDataState).length > 0){
            StorageService.setItem("user", StorageService.stringify(userDataState))
        }
    }, [userDataState.tutorial_progress])

    const handleOnNext = () => {
        switch (role){
            case RoleType.USER:
                updateUserTutorialProgressRequest(userDataState, { tutorial_progress: userDataState.tutorial_progress! + 1 }).then(() => {
                    dispatch(setUserData({ ...userDataState, tutorial_progress: userDataState.tutorial_progress! + 1 }))
        
                    if(props.routeOnNext){
                        
                        navigate(props.routeOnNext)
                    }
                    setIsVisible(false)
                })
            break
            case RoleType.ORGANIZATION:
                PatchOrganizationTutorialProgressRequest(orgDataState.id!,orgDataState.tutorial_progress! + 1).then(() => {
                    dispatch(setOrganizationData({...orgDataState,tutorial_progress: orgDataState.tutorial_progress! +1}))

                    if(props.routeOnNext){
                        
                        navigate(props.routeOnNext)
                    }

                    setIsVisible(false)
                })
            break
        }
        
        
    }

    const handleOnExit = () => {
        switch (role){
            case RoleType.USER:
                updateUserTutorialProgressRequest(userDataState, { tutorial_progress: 100 }).then(() => {
                    dispatch(setUserData({ ...userDataState, tutorial_progress: 100 }))
                })
                setIsVisible(false)
            break
            case RoleType.ORGANIZATION:
                PatchOrganizationTutorialProgressRequest(orgDataState.id!,100).then(() => {
                    dispatch(setOrganizationData({...orgDataState,tutorial_progress: 100}))
                })
                setIsVisible(false)
            break
        }
    }

    return (
        isVisible ? (<div className={`speechbubble speechbubble__${props.modifier}`}>
            <div className="speechbubble__row">
                {props.text}

            </div>
            <div className="speechbubble__bottom-panel">{props.hasNext && ( <button className="speechbubble__button" onClick={handleOnNext}>Weiter</button> )} <button className="speechbubble__button" onClick={handleOnExit} >Onboarding beenden</button></div>
        </div>) : (<></>)

    )
}
export default TutorialBubble
