import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../App/store"
import "../../../Styles/main.scss"
import { setModalData } from "../SharedSlices/ModalSlice"
import HeaderMenuItem from "./HeaderMenuItem"
import { useNavigate, useParams } from "react-router-dom"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import Image from "../Primitive/Image"
import Card from "../Primitive/Card"
import { logOut } from "../../User/UserDashboard/Logout"
import { profileDefault, organizationProfileDefault } from "../../../App/GlobaleVariables"
import { RoleType, SubscriptionType } from "../../../App/enums"
import { getUserProfileSettingsState } from "../../User/UserSettings/UserSettingsSlice"
import { GreyVectorDown } from "../Components/SvgIcons"
import TutorialBubble from "../Components/TutorialBubble"

function HeaderProfileMenu() {
    const { id } = useParams()
    let navigate = useNavigate()
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const organizationDataState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    let profieMenuRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
    const [profileMenuOpen, setProfileMenuOpen] = useState(false)
    const profileImage =
        role === RoleType.USER ? userDataState.profile_photo?.url_path || profileDefault : organizationDataState.photo?.url_path || organizationProfileDefault

    const onUserSettingsModalOpen = () => {
        dispatch(setModalData({ open: true, settings: true }))
        dispatch(getUserProfileSettingsState({ status: true, isHashtag: false }))
    }

    const onOrganizationSettingsModalOpen = () => {
        dispatch(setModalData({ open: true, organizationSettings: true }))
    }

    const onAddOrganizationModalOpen = () => {
        dispatch(setModalData({ open: true, addOrganization: true }))
    }

    useEffect(() => {
        const onMouseDownHandler = (event: Event) => {
            if (profileMenuOpen && profieMenuRef.current && !profieMenuRef.current.contains(event.target as HTMLElement)) {
                setProfileMenuOpen(false)
            }
        }
        document.addEventListener("mousedown", onMouseDownHandler)
    })

    const onRouteChange = (userPath?: string, organizationPath?: string) => {
        switch (role) {
            case RoleType.USER:
                navigate(userPath!)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                navigate(organizationPath!)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    const onGoPremiumScreenNavigation = () => {
        navigate(`/go-premium/${id}`)
        window.scrollTo(0, 0)
    }

    return (
        <div className="header__profile-item" ref={profieMenuRef}>
            {role === RoleType.USER && (
                <>
                <TutorialBubble text={translation.tutorial_user_profile} modifier="top-right speechbubble__header-profile-avatar" visible={userDataState.tutorial_progress == 4} hasNext={true} />    
                <TutorialBubble text={translation.tutorial_user_chat} modifier="top-right speechbubble__header-profile-chat" visible={userDataState.tutorial_progress == 5} hasNext={true} />    
                                <div className="header__menu-profile" onClick={() => setProfileMenuOpen(!profileMenuOpen)}>
                    <Image className="header__image" src={userDataState.profile_photo?.url_path || profileDefault} alt="profile" />
                    <GreyVectorDown />
                </div>
                </>
            )}

            

            {role === RoleType.ORGANIZATION && (
                <>
                    <TutorialBubble text={translation.tutorial_org_profile} modifier="top-right speechbubble__header-profile-avatar" visible={organizationDataState.tutorial_progress == 6} hasNext={true} routeOnNext={`/organization-profile/${organizationDataState.id}`} />
                    <div className="header__menu-profile" onClick={() => setProfileMenuOpen(!profileMenuOpen)}>

                    <Image className="header__image" src={organizationDataState?.photo?.url_path || organizationProfileDefault} alt="profile" />
                    <GreyVectorDown />

                </div>
                </>
            )}

            {profileMenuOpen && (
                <div className="header-profile-menu">
                    <Card>
                        <div className="header-profile-menu__card">
                            {role === RoleType.USER && (
                                <div onClick={() => onRouteChange("/user-profile", "")} className="header-profile-menu__profile">
                                    <div className="header-profile-menu__image">
                                        <Image
                                            className="image image__profile image__profile--size-medium "
                                            src={userDataState.profile_photo?.url_path || profileDefault}
                                        />
                                    </div>
                                    <div className="header-profile-menu__profile-text-container">
                                        <div className="header-profile-menu__profile-text"> {userDataState.first_name + " " + userDataState.last_name} </div>
                                    </div>
                                </div>
                            )}
                            {role === RoleType.ORGANIZATION && (
                                <div
                                    onClick={() => onRouteChange("", `/organization-profile/${organizationDataState.id}`)}
                                    className="header-profile-menu__profile"
                                >
                                    <div className="header-profile-menu__image">
                                        <Image
                                            className="image image__profile image__profile--size-medium "
                                            src={organizationDataState?.photo?.url_path || organizationProfileDefault}
                                        />
                                    </div>
                                    <div className="header-profile-menu__profile-text-container">
                                        <div className="header-profile-menu__profile-text"> {organizationDataState?.name} </div>
                                    </div>
                                </div>
                            )}

                            {role === RoleType.USER && (
                                <HeaderMenuItem
                                    onClick={onUserSettingsModalOpen}
                                    title={translation.settings}
                                    className="header-profile-menu__item header-profile-menu__item--border-bottom-3"
                                />
                            )}
                            {role === RoleType.ORGANIZATION && (
                                <HeaderMenuItem
                                    onClick={onOrganizationSettingsModalOpen}
                                    title={translation.settings}
                                    className="header-profile-menu__item header-profile-menu__item--border-bottom-3"
                                />
                            )}

                            {role === RoleType.USER && (
                                <HeaderMenuItem
                                    onClick={onAddOrganizationModalOpen}
                                    title={translation.addOrganization}
                                    className="header-profile-menu__item header-profile-menu__item--border-bottom-3"
                                />
                            )}
                            {role === RoleType.ORGANIZATION && organizationDataState.subscription_type === SubscriptionType.free && (
                                <HeaderMenuItem
                                    onClick={onGoPremiumScreenNavigation}
                                    title={translation.goPremium}
                                    className="header-profile-menu__item header-profile-menu__item--border-bottom-3 header-profile-menu__item--color-gold"
                                />
                            )}

                            <a
                                href="https://edusiia.com/faq/"
                                target="_blank"
                                rel="noreferrer"
                                className="header-profile-menu__item header-profile-menu__item--border-bottom-3"
                            >
                                {" "}
                                {translation.fAQ}
                            </a>

                            <HeaderMenuItem
                                onClick={logOut}
                                title={translation.logOut}
                                className="header-profile-menu__item header-profile-menu__item--hover-color-red header-profile-menu__item--text-color-white"
                            />
                        </div>
                    </Card>
                </div>
            )}{" "}
        </div>
    )
}

export default HeaderProfileMenu
