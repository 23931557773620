export const deutsch = {
    edusiiaTitle: "Das soziale Netzwerk für Bildung",
    signin: "Anmelden",
    signup: "Registrieren",
    feedWelcomeText: "willkommen bei edusiia! Wir wünschen dir viel Spaß und inspirierende Gespräche!",
    hello: "Hallo",
    posts: "Beiträge",
    newsMembersText: "Neue Mitglieder",
    sponsoredText: "Neue Angebote",
    here: "hier",
    edusiiaMatchingConnectViaDirectMessage:
        " Um deinen direkten Austausch zu fördern, vernetzen wir dich monatlich individuell per E-Mail mit jeweils einem anderen edusiia Mitglied. Beachte: Deine E-Mail-Adresse wird dabei mit anderen Mitgliedern geteilt.",
    edusiiaMatchingRevokeDecisionByEmail: "Deine Entscheidung kannst du jederzeit ändern. Sende einfach eine E-Mail an",
    edusiiaMatchingText: "Probier es doch einfach mal aus!",
    edusiiaMatchingYes: "Ja, ich bin dabei",
    edusiiaMatchingNo: "Nein, gerade nicht",
    receiveNewsLetter: "Ich bestätige, dass ich den Newsletter von edusiia gemäß der Datenschutzerklärung erhalten möchte.",
    revokeNewsLetter: "Ich kann diese Zustimmung jederzeit ohne Auswirkungen auf meine Mitgliedschaft widerrufen.",
    privacyPolicy: "Datenschutzerklärung",
    and: "und",
    termsOfUse: "Nutzungsbedingungen",
    join: "Los geht’s",
    none: "Nie",
    immediate: "Sofort",
    daily: "Täglich",
    weekly: "Wöchentlich",
    byEmail: "E-Mail-Benachrichtigungen",
    byWeb: "Plattform-Benachrichtigungen",
    commentUser: "Neue Kommentare zu deinen Beiträgen",
    commentOnSamePost: "Kommentare zu Beiträgen, die du kommentiert hast",
    roomInvitationUser: "Einladungen, Räumen beizutreten",
    tagsUser: "Neue Markierungen",
    roomInvitationReplyUser: "Reaktionen auf Raumeinladungen",
    roomJoinRequestUser: "Beitrittsanfragen für Räume, in denen du Moderator:in bist",
    roomJoinRequestOrganization: "Beitrittsanfragen für deine Räume",
    roomJoinRequestReplyUser: "Reaktionen auf Raumbeitrittsanfragen",
    addAdminUser: "Als Admin eingeladen",
    removeAdminUser: "Als Admin entfernt",
    eventCommentUser: "Kommentare zu Angeboten, die du kommentiert hast",
    eventCommentOrganization: "Neue Kommentare zu deinen Angeboten",
    newPostInRoomUser: "Neue Beiträge in Räumen, in denen du Mitglied bist",
    newPostInRoomOrganization: "Neue Beiträge in deinen Räumen",
    adminReplyUser: "Reaktionen auf Admin-Einladungen",
    newMembersOrganization: "Neue Mitglieder in deinen Räumen",
    save: "Speichern",
    fragen: "Fragen",
    antworten: "Antworten",
    konzeptionelles: "Konzeptionelles",
    fAQKonzeptionellesQuestionOne: "Was ist edusiia?",
    fAQKonzeptionellesAnswerOne:
        "edusiia ist die produktive Online-Community für Bildung. Wir helfen Bildungsorganisationen, ihre Bezugsgruppen langfristig untereinander und mit anderen zu vernetzen - für einen erfolgreichen Bildungswandel.",
    fAQKonzeptionellesQuestionTwo: "Was ist das Ziel dieser Plattform?",
    fAQKonzeptionellesAnswerTwo:
        "Bildung ist der Schlüssel zu positivem Wandel. Bildungsenthusiast:innen und -organisationen bekommen hier die Möglichkeit, sich langfristig interdisziplinär zu verbinden, um Herausforderungen gemeinsam zu lösen und schneller Wirkung zu erzielen. Da draußen gibt es wahnsinnig viele motivierte Akteur:innen und tolle Einzelinitiativen - es ist an der Zeit, sie einander bekannt zu machen und gemeinsam großflächig Wandel voranzutreiben.",
    fAQKonzeptionellesQuestionThree: "Wer darf bei edusiia mitmachen?",
    fAQKonzeptionellesAnswerThree1:
        "Alle, die aktiv und begeistert in der Bildung tätig sind, heißen wir herzlich bei edusiia willkommen - wir sprechen Organisationen und Menschen an, die bildungsenthusiastisch sind oder es werden wollen.",
    fAQKonzaptionellesAnswerThree2:
        "Was bedeutet bildungsenthusiastisch? Wir verbinden damit, dass man zuversichtlich, begeisterungsfähig, respektvoll, überzeugend, motivierend, ein Stück weit idealistisch, zukunftsorientiert, empathisch, leidenschaftlich, hilfsbereit und offen ist.",
    fAQKonzaptionellesAnwerThree3:
        " Vom Lehrer, Quereinsteiger und Elternvertreter an einer Grundschule, einer Gründerin eines EdTech-Startups, Schüler:innen, Logopäd:innen, Lehramtsstudierenden oder einer Pädagogik-Professorin an einer Universität, Eltern im Homeschooling, Angestellten in einer schulbildungsorientierten NGO, Stiftung oder in einem Unternehmen bis hin zu jemandem, der sich politisch mit dem Thema beschäftigt oder Werkzeuge für lebenslanges Lernen in ihrer Freizeit entwickelt.",
    fAQKonzeptionellesQuestionFour: "Gibt es ein Mindestalter, um sich bei edusiia anzumelden?",
    fAQKonzeptionellesAnswerFour1:
        "Bei edusiia können alle Bildungsenthusiast:innen ab 13 Jahren mitmachen. Alle, die zwischen 13 und 17 Jahren alt sind, übermitteln edusiia vor der Registrierung in der edusiia Community eine schriftliche Einverständniserklärung ihrer Erziehungsberechtigten. So möchten wir sicherstellen, dass unsere minderjährigen Mitglieder sich mit dem Wissen und der Unterstützung ihrer Eltern in unserer Community bewegen.",
    hier: "Hier",
    fAQKonzeptionellesAnswerFour2: "findet ihr weitere hilfreiche Informationen über Regeln und Meldemöglichkeiten bei edusiia. Mit der E-Mail-Adresse",
    fAQKonzeptionellesAnswerFour3: "geben wir minderjährigen Mitgliedern und Interessent:innen und ihren Eltern die Möglichkeit, uns gezielt zu kontaktieren.",
    fAQKonzeptionellesQuestionFive: "Was ist mit Bildungsenthusiast:innen gemeint?",
    fAQKonzeptionellesAnswerFive1: "Bildungsenthusiast:innen sind diejenigen, die aktiv und begeistert im Bildungsbereich tätig sind.",
    fAQKonzeptionellesAnswerFive2:
        "Bildungsenthusiast:innen sind zuversichtlich, begeisterungsfähig, respektvoll, überzeugend, motivierend, ein Stück weit idealistisch, zukunftsorientiert, empathisch, leidenschaftlich, hilfsbereit und offen.",
    fAQKonzeptionellesAnswerFive3:
        "Vom Lehrer, Quereinsteiger und Elternvertreter an einer Grundschule, einer Gründerin eines EdTech-Startups, Schüler:innen, Logopäd:innen, Lehramtsstudierenden oder einer Pädagogik-Professorin an einer Universität, Eltern im Homeschooling, Angestellten in einer schulbildungsorientierten NGO, Stiftung oder in einem Unternehmen bis hin zu jemandem, der sich politisch mit dem Thema beschäftigt oder Werkzeuge für lebenslanges Lernen in ihrer Freizeit entwickelt.",
    fAQKonzeptionellesQuestionSix: "Was versteht edusiia unter “besserer Bildung”?",
    fAQKonzeptionellesAnswerSix1:
        "Wir halten diesen Begriff bewusst offen, da wir Bildungsenthusiast:innen aus sehr unterschiedlichen Richtungen ansprechen möchten und jede:r für ihren:seinen Bereich definieren darf, was dies bedeutet. Das verbindende Element spiegelt sich in",
    unserenWerten: "Unseren Werten",
    fAQKonzeptionellesAnswerSix2: ", denen sich edusiia und die Bildungsentusiast:innen auf der Plattform verpflichtet fühlen.",
    fAQKonzeptionellesQuestionSeven: "Wer steckt hinter edusiia ?",
    fAQKonzeptionellesAnswerSeven:
        "Hinter edusiia steht ein interdisziplinäres Team von Bildungsenthusiast:innen, die Wandel in der Bildungswelt vorantreiben möchte. Mehr zu unseren Beweg- und Hintergründen findest du unter",
    uberUns: "Über uns",
    fAQKonzeptionellesQuestionEight: "Kostet die Mitgliedschaft bei edusiia Geld?",
    fAQKonzeptionellesAnswerEight: "Nein, die Mitgliedschaft bei edusiia ist kostenlos.",
    fAQKonzeptionellesQuestionNine: "Wie ist der Datenschutz bei edusiia geregelt?",
    fAQKonzeptionellesAnswerNine1:
        'Der Schutz deiner Daten ist für uns ein wichtiges Anliegen. Deshalb hosten wir zum Beispiel unsere Google-Schriften lokal, damit der Datenaustausch mit Google für dich entfällt. Außerdem nutzen wir das Analyse-Tool "Matomo". Die Software läuft dabei ausschließlich auf den Servern unserer Webseite. Eine Speicherung deiner personenbezogenen Daten findet nur dort statt, deine IP-Adresse wird auch für uns nur anonymisiert angezeigt',
    fAQKonzeptionellesAnswerNine2: "findest du unsere ausführliche Datenschutzerklärung.",
    fAQKonzeptionellesQuestionTen: "Was ist der Vorteil von edusiia gegenüber Facebook-Gruppen, Bildungs-Foren etc.?",
    fAQKonzeptionellesAnswerTen1:
        "edusiia ist gezielt auf Akteur:innen der Bildungslandschaft zugeschnitten. Unsere Community regt zu Austausch, Kollaboration und dem Lösen individueller Herausforderungen an.",
    fAQKonzeptionellesAnswerTen2:
        "Unsere Mitglieder erhalten auf sie zugeschnittene Vorschläge zu neuen Themen und für sie interessante Nutzer:innen. edusiia dient dem Ziel, den Bildungsbereich voranzutreiben und wird langfristig kuratierte Inhalte für die Community zur Verfügung stellen.",
    fAQEdusiiaMatchingQuestion: 'Was ist das "edusiia Matching"?',
    fAQEdusiiaMatchingAnswer1:
        "Aktuell nutzen wir für die Community eine fertige Software-Lösung, die nur sehr bedingt an die Bedürfnisse der Mitglieder angepasst werden kann. Bis die erste Version unserer eigenen Plattform fertig ist (wir arbeiten fleißig an der Umsetzung!), dient das edusiia Matching als Möglichkeit, unsere Mitglieder einander niedrigschwellig bekannt zu machen und ihnen einen Austausch zu ermöglichen, der sie sowohl individuell als auch innerhalb ihrer Projekte voranbringt. Wir übernehmen die initiale Vorstellung, alles Weitere überlassen wir den Vernetzten.",
    fAQEdusiiaMatchingAnswer2: "Was heißt das konkret?",
    fAQEdusiiaMatchingAnswer3:
        "Beim edusiia Matching stellen wir zum Monatsanfang teilnehmende Mitglieder einander via E-Mail vor, so dass Kontakte entstehen, die sonst vielleicht nicht entstanden wären. Die Vernetzung geschieht jeweils 1-zu-1 und auf der Grundlage der Angaben im Anmeldeformular und in der Community.",
    fAQEdusiiaMatchingAnswer4: "Bitte beachte, dass wir deine Email-Adresse beim edusiia Matching mit einem anderen Mitglied der Community teilen.",
    fAQEdusiiaMatchingAnswer5: "Schreibe eine Nachricht an",
    fAQEdusiiaMatchingAnswer6: ", wenn du deine Angabe zur Teilnahme am edusiia Matching ändern möchtest oder Fragen dazu hast.",
    anmeldung: "Anmeldung",
    fAQAnmeldungQuestionOne: "Warum muss ich mich anmelden, um mitzudiskutieren?",
    fAQAnmeldungAnswerOne1:
        "edusiia richtet sich gezielt an Bildungsenthusiast:innen, die sich für Bildung begeistern und motiviert und positiv an Bildungsthemen arbeiten. Durch die Anmeldung und damit verbundene Beantwortung zweier Fragen, möchten wir sicherstellen, dass alle Teilnehmenden sich auch als Bildungsenthusiast:innen verstehen und sich",
    fAQAnmeldungAnswerOne2: "verpflichtet fühlen.",
    fAQAnmeldungQuestionTwo: "Muss ich mich mit meinem Klarnamen anmelden?",
    fAQAnmeldungAnswerTwo: "Nein. Jedoch ist es empfehlenswert, damit dich Bekannte, Kolleg:innen und potentielle Kollaborateur:innen besser finden können.",
    fAQAnmeldungQuestionThree: "Muss ich meine:n Arbeitgeber:in angeben?",
    fAQAnmeldungAnswerThree:
        'Nein. Jedoch empfehlen wir dir die Angabe, damit dein Hintergrund und Kontext von allen Bildungsenthusiast:innen besser eingeordnet werden kann. Die entsprechende Information kannst du in deinem Profil unter "Titel" einfügen.',
    fAQAnmeldungQuestionFour: "Kann ich ein Profil für mein Unternehmen anlegen?",
    fAQAnmeldungAnswerFour1:
        "edusiia Mitglieder können in ihrer Profilbeschreibung ihre:n Arbeitgeber:in oder ihr eigenes Unternehmen nennen und vorstellen. Aktuell können sich ausschließlich Individuen bei edusiia anmelden, d. h. es gibt derzeit noch keine Möglichkeit, Profile für Unternehmen zu erstellen.",
    fAQAnmeldungAnswerFour2:
        "Zum einen möchten wir hiermit vermeiden, dass edusiia Profile vornehmlich für geschäftliche Zwecke genutzt werden. Zum anderen liegt uns gerade in der Anfangsphase viel daran, dass alle Mitglieder auf Augenhöhe miteinander in Kontakt treten, sich vernetzen und austauschen. Wir glauben fest daran, dass diese Verbindung zwischen Individuen essentiell für einen erfolgreichen Austausch und praktische Veränderung ist.",
    funktionen: "Funktionen",
    fAQFunktionenQuestionOne: "Wie funktioniert der Feed?",
    fAQFunktionenAnswerOne1:
        "Der Feed ist die Sammlung von den auf edusiia erstellten Beiträgen, der dir und allen anderen Mitgliedern den Inhalt auf eine sehr kontextbezogene Art und Weise liefert. Jeder Feed wird auf Basis individueller Faktoren wie den gefolgten Themen und Gruppen zusammengestellt. Folgst du einer Person, erhältst du eine Benachrichtigung, sobald sie einen Beitrag veröffentlicht.",
    fAQFunktionenAnswerOne2: "Zu dem edusiia Haupt-Feed haben alle Mitglieder Zugang, nachdem sie sich angemeldet haben.",
    fAQFunktionenQuestionTwo: "Warum sollte ich eigene Lösungen auf edusiia posten?",
    fAQFunktionenAnswerTwo:
        "edusiia eignet sich sehr gut, Bildungsideen mit einer interdisziplinären Gruppe an Menschen zu diskutieren, Feedback einzuholen und zu testen. Letztendlich sind alle Teilnehmenden daran interessiert, den Bildungsbereich voranzubringen.",
    fAQFunktionenQuestionThree: "Was passiert in den Gruppen?",
    fAQFunktionenAnswerThree1:
        "Gruppen sind eine Art Sub-Communities innerhalb der großen edusiia Community. Sie geben dir eine gute Möglichkeit, ein eingegrenztes Bildungsthema mit anderen Interessierten zu diskutieren und zu bearbeiten. Beiträge, die innerhalb einer Gruppenseite veröffentlicht werden, werden automatisch dieser Gruppe zugeordnet. Nur die Mitglieder dieser Gruppe können den Inhalt der Gruppe in ihrem Feed sehen.",
    fAQFunktionenAnswerThree2: "Es gibt drei Gruppenarten:",
    offentlicheGruppen: "Öffentliche Gruppen",
    fAQFunktionenAnswerThree3:
        'sind für alle edusiia Mitglieder zugänglich und jedes edusiia Mitglied kann den Inhalt vor dem Beitritt einsehen. Für den Beitritt zu einer öffentlichen Gruppe ist also keine Genehmigung erforderlich. Öffentliche Gruppen sind auch auf der Seite "Gruppen" sichtbar.',
    privateGruppen: "Private Gruppen",
    fAQFunktionenAnswerThree4:
        'hingegen sind nur für Gruppenmitglieder editierbar. Alle edusiia Mitglieder können die in der Gruppe veröffentlichten Inhalte vor dem Beitritt sehen. Der Beitritt zu einer privaten Gruppe erfordert die Genehmigung des Gruppen-Admins, des/der Gruppenmoderator:in oder des edusiia Admins. Private Gruppen sind genauso wie öffentliche Gruppen auf der Seite "Gruppen" sichtbar. ',
    geheimeGruppen: "Geheime Gruppen",
    fAQFunktionenAnswerThree5:
        'sind wie private Gruppen, mit dem Unterschied, dass sie nicht auf der "Gruppen"-Seite aufgeführt sind, so dass Nutzer:innen sie nicht entdecken können, es sei denn, sie werden in die Gruppe eingeladen. Die einzige Möglichkeit, einer geheimen Gruppe beizutreten, ist die Einladung durch den Gruppenadmin, die Gruppenmoderator:innen oder durch edusiia Admins. Letztere können den Inhalt der Gruppe sehen, auch wenn sie nicht zu ihren Mitgliedern gehören.',
    fAQFunktionenQuestionFour: "Was sind “Themen”?",
    fAQFunktionenAnswerFour:
        'Themen sind wie Schlagworte (Tags), die zum Organisieren von Inhalten verwendet werden. Themenseiten sind öffentlich und für jeden zugänglich. Einem Beitrag können mehrere Themen zugewiesen werden. Unter "Alle Themen" werden die Themen angezeigt, denen mindestens 3 Bildungsenthusiast:innen folgen.',
    fAQFunktionenQuestionFive: "Wer kann meine Beiträge sehen?",
    fAQFunktionenAnswerFive:
        "Wenn du deine Beiträge über den Home-Feed veröffentlichst, sind sie für alle Personen, die dir folgen oder nach dem von dir gewählten Thema suchen, sichtbar. Du kannst jedoch auch auswählen, dass deine Beiträge in einer bestimmten Gruppe veröffentlicht werden und sie damit nur für die Gruppenteilnehmer:innen sichtbar sind.",
    fAQFunktionenQuestionSix: "Wie kann ich weitere Bildungsenthusiast:innen zu edusiia einladen?",
    fAQFunktionenAnswerSix1:
        "Wir arbeiten momentan an einer Lösung, die dir ermöglicht, auf einfache und schnelle Weise andere Bildungsenthusiast:innen per E-Mail einzuladen. Verschicke bitte bis dahin den Link",
    fAQFunktionenAnswerSix2: "mit der Bitte, sich über den “Ich bin dabei”-Button anzumelden.",
    fAQHaveAnyQuestions: "Hast du die Antwort auf deine Frage hier nicht gefunden? Schreibe uns gerne eine Nachricht über unser",
    kontaktformular: "Kontaktformular",
    oderEineEMailAn: "oder eine E-Mail an",
    doYouWantToDeleteAccount: "Du möchtest dein persönliches Konto löschen?",
    deleteAccountAnytime: "Du kannst dein Konto jederzeit löschen.",
    userDeleteAccountWithOrganization1: "Du bist Inhaber:in von mindestens einem Organisationsprofil. ",
    userDeleteAccountWithOrganization2:
        "Um dein persönliches Konto löschen zu können, musst du vorher dein Organisationsprofil löschen oder die Inhaberschaft an ein anderes Mitglied übertragen. Gehe dazu in dein Organisationsprofil und klicke auf “Inhaberschaft übertragen”.",
    userDeleteAccountWithoutOrganization2: "auf.",
    userDeleteAccountEduFanMember1:
        "Schade, dass du nicht mehr Teil der Community sein möchtest! Falls es etwas gibt, das dich als Mitglied bei uns halten würde, nimm jederzeit Kontakt zu uns über",
    userDeleteAccountEduFanMember2: " auf. WICHTIG: Bitte beachte, dass du deine eduFan Mitgliedschaft gesondert kündigen musst.",
    deleteAccountReason: "Wir würden uns freuen zu erfahren, warum du dein Konto löschen möchtest:",
    acceptConsequences: "Ich möchte mein edusiia Konto gemäß den Nutzungsbedingungen unwiderruflich löschen.",
    learnMoreAboutThat: "Weitere Informationen findest du",
    delete: "Löschen",
    howOthers: "Wie andere",
    viewMyProfile: "mein Profil sehen",
    firstName: "Vorname",
    lastName: "Nachname",
    email: "E-Mail",
    securePassword: "Passwort",
    newSecurePassword: "Neues Passwort",
    repeatNewSecurePassword: "Neues Passwort wiederholen",
    name: "Name",
    surname: "Nachname",
    password: "Passwort",
    newPassword: "Neues Passwort",
    repeatSecurePassword: "Passwort wiederholen",
    repeatPassword: "Passwort wiederholen",
    howDidYouLearnAboutEdusiia: "Wie hast du von edusiia erfahren?",
    next: "Weiter",
    thisFieldIsRequired: "Dies ist ein Pflichtfeld.",
    thisFieldContainsOnlyLetters: "Hier kannst du nur Buchstaben, Leerzeichen und Bindestriche eingeben.",
    enterValidEmail: "Bitte gib eine gültige E-Mail-Adresse ein.",
    passwordLongerThan8: "Das Passwort muss mind. 8 Zeichen lang sein und mind. einen Buchstaben und eine Zahl enthalten.",
    organizationError:
        "Leider ist beim Hinzufügen deiner Organisation ein Fehler aufgetreten. Schließe deine Registrierung ab und füge deine Organisation später hinzu.",
    passwordsDontMatch: "Die Passwörter stimmen nicht überein.",
    pleaseSelectAnAnswer: "Bitte wähle eine Antwort aus.",
    pleaseSelect: "Bitte auswählen...",
    addOrganizationProfile: "Organisationsprofil hinzufügen",
    addOrganizationIntroduction: "Zusätzlich zu deinem persönlichen Profil kannst du hier auch gleich ein Profil für deine Organisation anlegen.",
    nameOfOrganization: "Name deiner Organisation",
    isOrganizationRepresentative:
        "Ich bestätige, dass ich berechtigt bin, im Namen der angegebenen Organisation ein Profil bei edusiia anzulegen und es zu verwalten.",
    tooltipOrganizationExample: "Organisationsprofile kannst du auch später noch jederzeit anlegen.",
    myRoleinEducation: "Meine Rolle im Bildungsbereich",
    whyAmIAtEdusiia: "Warum ich bei edusiia bin",
    settings: "Einstellungen",
    addOrganization: "Organisation hinzufügen",
    logOut: "Abmelden",
    fAQ: "FAQ",
    myExperience: "Meine Erfahrungen",
    noExperience: "Erfahrungslos? Das glauben wir nicht.",
    seeAllResults: "Alle Ergebnisse ansehen",
    members: "Mitglieder",
    public: "Öffentlich",
    private: "Privat",
    myRooms: "Meine Räume",
    noRooms: "Ups, hier ist noch jemand raumlos.",
    Admin: "Admin",
    edusiiaMatching: "edusiia Matching",
    OurRooms: "Unsere Räume",
    CreateRoom: "Raum erstellen",
    YouHaventAddedARoomYet: "Ups, hier ist noch jemand raumlos.",
    OurEmployees: "Unser Team",
    YouHaventAddAnyEmployeeYet: "Stemmst du das alles allein?",
    noEmployeesToBeShow: "Ich stemme das alles ohne Team.",
    whatWeDoForEducation: "Unsere Rolle im Bildungsbereich",
    emailInstructionSent: "Gleich geschafft…",
    pleaseFollowTheInstructions: "Bitte folge den Hinweisen,",
    weHaveSentToYourInbox: "die wir dir soeben per E-Mail zugeschickt haben.",
    forgotPassword: "Passwort vergessen",
    pleaseEnterYourRegisteredEmailAddress: "Bitte gib deine E-Mail-Adresse ein, mit der du dich bei edusiia registriert hast. ",
    reset: "Passwort zurücksetzen",
    resetYourPassword: "Passwort zurücksetzen",
    minimumCharacters: "Das Passwort muss mind. 8 Zeichen lang sein und ",
    mustContain: "",
    upperAndLowercaseNumbersAndSimbols: "mind. einen Buchstaben und eine Zahl enthalten",
    confirmNewPassword: "Neues Passwort bestätigen",
    changePassword: "Passwort ändern",
    theOnlineCommunity: "Das soziale Netzwerk",
    forEducation: "für Bildung",
    weConnectOrganizations: "Wir verbinden Menschen",
    andPeopleWhoWantToMake: "und Organisationen",
    educationBetter: "aus allen Bildungsbereichen.",
    aboutUs: "Über uns",
    ourValues: "Unsere Werte",
    eventCalendar: "Veranstaltungskalender",
    supportEdusiia: "Presse",
    youthProtection: "Jugendschutz",
    privacy: "Datenschutz",
    impressum: "Impressum",
    home: "Home",
    notifications: "Benachrichtigungen",
    rooms: "Räume",
    chat: "Chat",
    bookmarks: "Lesezeichen",
    search_desktop: "Suche nach Personen, Organisationen, Räumen oder Themen",
    search_mobile: "Suchen…",
    search_chat: "Suchen oder Chat starten...",
    whoCanReachMe: "Privatsphäre",
    otherMembersCanSendMePrivateMessages: "Mitglieder können mir Diärektnachrichten schicken",
    allowProfileInformationToBeSearched: "Meine Profilinformationen erscheinen in den edusiia Suchergebnissen.",
    doYouLikeYour: "Gefällt dir unser Netzwerk?",
    experienceInEdusiia: "Dein Beitrag hilft, edusiia besser zu machen.",
    considerDonatingToOurCause: "Vielen Dank für deine Unterstützung!",
    donate: "eduFan werden",
    pinboard: "Angebote",
    markAllRead: "Alle als gelesen markieren",
    by: "von",
    joinEdusiiaToday: "Neu bei edusiia?",
    AllSettings: "Alle Einstellungen",
    Profile: "Profil",
    Notification: "Benachrichtigungen",
    Privacy: "Privatsphäre",
    deleteAccount: "Konto löschen",
    DeleteAccount: "Konto löschen",
    headline: "Dein Titel",
    subheadline: "Deine Beschreibung",
    mainText: "Main text",
    organizationName: "Name deiner Organisation",
    passwordRequirement1: "Das Passwort muss mind. 8 Zeichen lang sein und",
    passwordRequirement2: "mind. einen Buchstaben und eine Zahl enthalten.",
    create: "Erstellen",
    createOrganization: "Organisationsprofil erstellen",
    about: "Unsere Rolle im Bildungsbereich",
    website: "Website der Organisation",
    optional: "(optional)",
    permissions: "Berechtigung",
    bookmark: "Merken",
    membersWhoJoinedEdusiia: "edusiias neueste Mitglieder",
    changePhotoProfile: "Profilbild ändern",
    viewPhotoProfile: "Profilbild anzeigen",
    maxImageSize: "(max. 4 MB)",
    recommendedProfilePhoto: "(500 x 500 Pixel empfohlen)",
    recommendedCoverPhoto: "(2400 x 600 Pixel empfohlen)",
    viewCoverPhoto: "Titelbild anzeigen",
    changeCoverPhoto: "Titelbild ändern",
    noDescriptionYet: "Noch keine Beschreibung hinzugefügt",
    position: "Rolle",
    companyName: "Organisation",
    startDate: "Startdatum",
    endDate: "Enddatum",
    deleteExperience: "Erfahrung löschen",
    day: "Tag",
    month: "Monat",
    months: "Monate",
    year: "Jahr",
    currentlyWorking: "Ich bin aktuell in dieser Rolle aktiv",
    present: "Heute",
    crop: "Speichern",
    location: "Ort",
    locationPlaceholder: "Stadt, Land",
    removeEmployee: "Teammitglied entfernen",
    removeEmployeeQuestion1: "Bist du sicher, dass du",
    removeEmployeeQuestion2: "als Teammitglied entfernen möchtest?",
    removeRoomMemberTitle: "Mitglied entfernen",
    removeRoomMember: "Bist du sicher, dass du ",
    removeRoomMemberQuestion1: "aus deinem Raum entfernen möchtest?",
    remove: "Entfernen",
    addEmployees: "Teammitglieder hinzufügen",
    add: "Hinzufügen",
    forProfitOrganization: "For-Profit-Organisation",
    nonProfitOrganization: "Non-Profit-Organisation",
    forProfit: "For-Profit-Organisation",
    nonProfit: "Non-Profit-Organisation",
    halloTeamOf: "Hallo liebes Team von ",
    addTitle: "Titel deines Angebots…",
    addDescription: "Beschreibe dein Angebot möglichst kurz und präzise…",
    confirmPassword: "Passwort bestätigen",
    myOrganizations: "Meine Organisationen",
    individual: "Person",
    organization: "Organisation",
    goPremium: "Jetzt upgraden",
    account: "Mein Konto",
    deleteRoom: "Raum löschen",
    areYouSureToDeleteRoom: "Bist du sicher, dass du diesen Raum löschen möchtest?",
    roomInvitationRequests: "Beitrittsanfragen",
    organizationCommentsNotification: "Neue Kommentare",
    organizationTagsNotification: "Markierungen",
    organizationRoomJoinNotification: "Antwort auf Beitrittsanfragen",
    organizationAdminNotification: "Antwort auf Admin-Einladung",
    organizationPostsNotification: "Neue Beiträge",
    organizationMembersNotification: "Neues Mitglied",
    contributionAmount: "Wähle einen Betrag ",
    perMonth: "pro Monat",
    perYear: "pro Jahr",
    freeMonth: "(1 Monat kostenlos)",
    otherAmount: "Anderer Betrag",
    iWouldLikeToSuportEdusiia:
        "Hiermit bestätige ich, dass ich die edusiia GmbH gemäß den Zahlungsbedingungen für Förderungen unterstütze. Die Förderung kann ich jederzeit beenden.",
    guidelinesForDonationsApply: "Es gelten die Förderrichtlinien.",
    donateOnceByBankTransfer: "Gründe für eine eduFan Mitgliedschaft",
    myProfileInfoCanBeDisplayedInSearchResults: "Meine Profilinformationen erscheinen in den edusiia Suchergebnissen",
    myProfileInfoCanBeFoundInSearchEngines: "Suchmaschinen außerhalb von edusiia können einen Link zu meinem Profil anzeigen.",
    wantToDeleteOrganizationalAccount: "Du möchtest dein Organisationsprofil unwiderruflich löschen?",
    beforeProceedingWarning: "Bevor du das tust, beachte bitte Folgendes:",
    asTheOwnerYouCanDelete: "Als Inhaber:in dieses Organisationsprofils kannst du es jederzeit löschen. ",
    firstOrganizationDeleteAccountWarning:
        "Aktive kostenpflichtige Mitgliedschaften musst du vor dem Löschen deines Profils kündigen (Einstellungen → Mitgliedschaft). Bereits geleistete Zahlungen werden nicht erstattet, wenn du dein Organisationsprofil löschst, bevor deine Mitgliedschaft endet.",
    secondOrganizationDeleteAccountWarning:
        "Neben den Profilinformationen werden auch die von dir oder anderen Admins deiner Organisation erstellten Räume aus dem Netzwerk gelöscht. Mitglieder haben keinen Zugriff mehr auf die dort geteilten Inhalte.",
    thirdOrganizationDeleteAccountWarning1:
        "Tipp: Du kannst die Inhaberschaft und die damit zusammenhängenden Rechte an ein anderes Mitglied übertragen. Gehe dazu in dein Profil und klicke auf “Inhaberschaft übertragen”.",

    permissionNeeded: "Erlaubnis erforderlich!",
    noPermissionForPage: "Dir fehlt die Berechtigung zum Zugriff auf diese Seite!",
    goToDashboard: "Zurück zur Übersichtsseite",
    invitationLink: "Einladungslink",
    copyLink: "Link kopieren",
    roomName: "Name des Raumes",
    select: "Auswählen",
    addPhoto: "Foto hinzufügen",
    uploadPhoto: "Foto hochladen",
    roomManagers: "Moderator:in",
    allowMembersToChatAndShareDocuments: "Erlaube Mitgliedern, zu schreiben und Inhalte zu teilen",
    allowMembersToInviteOtherMembers: "Erlaube Mitgliedern, andere Mitglieder einzuladen",
    allowMembersRequestToJoinTheRoom: "Erlaube Mitgliedern, eine Beitrittsanfrage zu stellen",
    invite: "Einladen",
    description: "Beschreibung",
    typeYourMessage: "Was möchtest du teilen?",
    addAComment: "Kommentar hinzufügen…",
    roomCreator: "Gastgeber:in",
    roomManager: "Moderator:in",
    seeMoreText: "Alles anzeigen",
    seeLessText: "Weniger anzeigen",
    comments: "Kommentare",
    allPosts: "zu weiteren Raum-Beiträgen",
    joinEvent: "Join Event",
    goPremiumToCreateMoreRooms: "Weitere Räume erstellen",
    editRoom: "Raum bearbeiten",
    deleteThisRoom: "Raum löschen",
    deletingThisRoomWillLoseAllAssets: "(wenn du den Raum löschst, verlierst du alle seine Inhalte)",
    roomInformation: "Room Information",
    share: "Teilen",
    shareToRoom: "Im Raum teilen",
    eventLink: "Link zum Angebot teilen",
    seeAllDetails: "Alles anzeigen",
    showLess: "Weniger anzeigen",
    ifYouWantToChangeThisInfo: "Wenn du diese Einstellung ändern möchtest,",
    pleaseContactUsVia: "schreibe uns bitte eine E-Mail an",
    changeInfoMain: " mitgliedschaft@edusiia.com",
    alreadyHaveAnAccount: "Du bist bereits edusiia Mitglied?",
    upgradeYourProfile: "Upgrade dein Profil",
    unleashYourFullPotential: "Erziele mehr Wirkung.",
    hi: "Hallo liebes",
    team: "Team",
    checkOutWhatOurUpgradedProfilesHaveToOfferYou:
        "mit dem edusiia Premium-Profil kannst du mehr Mitglieder in deinen Räumen versammeln. Hier erfährst du, welche weiteren Vorteile ein Upgrade für dich und deine Organisation hat.",
    premiumSmall: "Premium Small",
    premiumMedium: "Premium Medium",
    premiumLarge: "Premium Large",
    premium: "Premium",
    book: "Buchen",
    custom: "Weitere Unterstützung benötigt?",
    contactTheEdisiiaTeam:
        "Wir helfen dir bei Themen rund um PR und Kommunikation oder bei der Moderation in deinen Räumen. Kontaktiere unsere Co-Gründerin Anna unverbindlich unter ",
    wellCreateAsuitablePackage: "und wir entwickeln ein passendes Angebot für dich.",
    edusiiaMail: "anna@edusiia.com",
    checkOutWhatYouGetByUpgrading: "Vergleiche unsere Pakete und wähle das passende für deine Organisation:",
    forFurtherInformation: "Bitte beachte auch unsere ",
    basic: "Basis",
    createYourCustomizablePage: "Erstelle und gestalte dein eigenes Profil",
    showcaseYourIdeas: "Stelle neue Produkte, Jobs und weitere Angebote in deinem persönlichen Bereich vor",
    joinAndPartecipateInOtherRooms: "Tausche dich mit anderen Mitglieder aus",
    createOnePrivate: "Erstelle einen Raum für maximal 10 Mitglieder",
    displayYourPinboard: "Präsentiere deine Produkte, Jobs und weiteren Angebote anderen Mitgliedern auf ihrer Übersichtsseite",
    createUnlimitedRooms: "Erstelle 5 Räume mit jeweils 200 Mitgliedern (individuelle Anpassungen möglich)",
    deleteEvent: "Angebot löschen",
    nopostsYet: "Noch keine Beiträge",
    edit: "Bearbeiten",
    member: "Mitglied",
    organizationEmail: "E-Mail-Adresse",
    Email: "E-Mail",
    newestOrganizations: "Neue Organisationen",
    answers: "Antworten",
    answer: "Antwort",
    decline: "Ablehnen",
    accept: "Akzeptieren",
    joinRoom: "Raum beitreten",
    leaveRoom: "Raum verlassen",
    pending: "Angefragt",
    onlyMembersCanSeeOtherMembers: "Nur Raum-Mitglieder können andere Mitglieder dieses Raumes sehen",
    cantSeeRoomContent: "Nur Raum-Mitglieder können die Inhalte dieses Raumes sehen",
    onlyMembersCanPostInRoom: "Tritt dem Raum bei, um etwas teilen zu können.",
    invited: "Eingeladen",
    noEndDateProvided: "Gib ein Enddatum ein oder markiere die Erfahrung als aktuell aktiv.",
    HowManyEmployeessYourOrganizationHave: "Wie viele Mitarbeitende hat deine Organisation?",
    oneToTen: "1-10 Mitarbeitende",
    elevenToOneHundred: "11-100 Mitarbeitende",
    moreThanOneHundred: ">100 Mitarbeitende",
    howMuchRevenuePerYear: "Wie viel Umsatz macht deine Organisation jährlich?",
    youHaveNoNotifications: "Du hast keine Benachrichtigungen",
    buy: "Auswählen",
    checkOutYour: "hier erhältst du einen Überblick über die aktuellen ",
    currentSubscriptionPlan: "Mitgliedschaftspakete",
    checkoutYourOrg: "hier erhältst du einen Überblick über deine aktuelle ",
    membership: "Mitgliedschaft",
    individuals: "Personen",
    organizations: "Organisationen",
    allResults: "Alle Ergebnisse",
    events: "Angebote",
    noResultThatContain: "Keine Ergebnisse zu ",
    wasFound: "gefunden.",
    nothingToShowYet:
        "Wir konnten keine Ergebnisse zu deiner Suche finden. Probiere es mit anderen Suchbegriffen oder starte selbst einen Austausch zu deinem Thema.",
    room: "Raum",
    aPlanUpgradeTakesEffectImmediately:
        "Das Upgrade deiner Mitgliedschaft wird sofort durchgeführt. Da edusiia eine im Voraus zu bezahlende Plattform ist, wird die potentiell entstehende Differenz mit deiner vorherigen Zahlung verrechnet.",
    upgradeConfirmation: "Bestätigung des Upgrades",
    warningUpgrade: "Bitte beachte, dass die Veränderungen ab der kommenden Abrechnungsperiode wirksam werden.",
    warningUpgrade2: "Achtung: Wenn du dich noch in der Testphase befindest, wird die Änderung sofort wirksam und die restliche Testphase verfällt.",
    upgrade: "Zahlungspflichtig bestellen",
    editPost: "bearbeiten",
    deletePost: "löschen",
    editComment: "bearbeiten",
    deleteComment: "löschen",
    areYouSureToDeletePost: "Bist du sicher, dass du diesen Beitrag löschen möchtest?",
    areYouSureToDeleteComment: "Bist du sicher, dass du diesen Kommentar löschen möchtest?",
    areYouSureToDeletePreview: "Bist du sicher, dass du die Link-Vorschau entfernen möchtest?",
    cancel: "Abbrechen",
    donationGuidelines: "Zahlungsbedingungen für Förderungen",
    Förderung: "Förderung",
    Förderung11:
        " Mit der Fördermitgliedschaft leiste ich bis zu meiner Kündigung an die edusiia GmbH eine regelmäßig wiederkehrende Zahlung in Höhe des von mir gewählten Betrags ohne Anspruch auf eine Gegenleistung (Schenkung).",
    Förderung12:
        " Da die edusiia GmbH nicht vom Finanzamt als gemeinnützig anerkannt ist, kann leider keine Zuwendungsbescheinigung (Spendenquittung) ausgestellt werden.",
    Zahlung: "Zahlung",
    Zahlung21: " Ich verpflichte mich für die Laufzeit meiner Fördermitgliedschaft zur Zahlung des von mir gewählten monatlichen Betrags.",
    Zahlung22:
        "Je nach der von mir gewählten Zahlungsart gelten ergänzend die Bedingungen des von mir erteilten SEPA-Mandats bzw. des von mir gewählten Zahlungsdienstes.",
    LaufzeitUndKündigung: "Laufzeit und Kündigung",
    LaufzeitUndKündigung3:
        "Die Fördermitgliedschaft kann ich jederzeit fristlos kündigen. Die Kündigung erfolgt über das Benutzerkonto oder in Textform (z.B. per E-Mail).",
    AnwendbaresRech: "Anwendbares Recht; Verhältnis zu den allgemeinen edusiia Nutzungsbedingungen",
    AnwendbaresRech41: "Es gilt deutsches Recht.",
    AnwendbaresRech42: "Die Nutzungsbedingungen für die edusiia Fördermitgliedschaft gelten zusätzlich zu den allgemeinen edusiia Nutzungsbedingungen.",
    bankTransfer: "eduFan Mitgliedschaft",
    bankTransferParagraph:
        "Du ziehst großen Mehrwert aus edusiia und findest wir sollten unseren Wirkungsrahmen erweitern? Mit einer eduFan Mitgliedschaft wirst du ein wichtiger Teil des besonderen Kreises der edusiia Unterstützer:innen! Du hilfst uns so dabei, unser Netzwerk möglichst vielen Menschen und Organisationen anbieten und es weiterentwickeln zu können. Als Dankeschön bekommst du von uns ein exklusives Grafik-Set, um dein Profil bei edusiia besonders prägnant zu gestalten. ",
    wirDankenDirSEHR: "Wir danken dir sehr!",
    edusiiaGmbH: "edusiia GmbH",
    IBAN: "IBAN",
    edusiiaIBAN: "DE15 4306 0967 1258 9457 00",
    BIC: "BIC",
    edusiiaGENODEM1GLS: "GENODEM1GLS",
    Bank: "Bank",
    bankName: "GLS Gemeinschaftsbank",
    Vwz: "Vwz.",
    edusiiaVwz: "*Vor- und Nachname*, Förderung",
    postsAtMyRooms: "Neue Beiträge",
    postiveEmailVerification: "Deine E-Mail-Adresse wurde bestätigt. Du kannst dich jetzt anmelden. Viel Spaß!",
    confirmEmailMessage: "Bitte bestätige deine E-Mail-Adresse über den Link, den wir dir gerade per E-Mail zugeschickt haben. ",
    featureComingSoon: "Funktion in Planung",
    payments: "Mitgliedschaft",
    checkOutOrCancelYour: "Verwalte deine ",
    currentDonationPlan: "eduFan Mitgliedschaft",
    thankYouForYourSupport: "Wir danken dir sehr für deine Unterstützung!",
    subscription: "Mitgliedschaft",
    VATnote: "*Alle Preise sind Nettopreise und gelten zzgl. der gesetzlichen MwSt.",
    roomCreatedSuccessfully: "Dein Raum wurde erfolgreich erstellt.",
    deleteRoomSuccessfully: "Dein Raum wurde gelöscht.",
    editRoomSuccessfully: "Dein Raum wurde erfolgreich bearbeitet.",
    organizationCreatedSuccessfully: "Dein Organisationsprofil wurde erfolgreich erstellt.",
    roomMemberRemoval: "Das Mitglied wurde aus dem Raum entfernt.",
    deleteAccountSuccessfully: "Das Konto wurde gelöscht.",
    employeeRemoval: "Das Mitglied wurde aus der Liste entfernt.",
    deleteOrganizationSuccessfully: "Die Organisation wurde gelöscht.",
    newExperienceAddedSuccessfully: "Deine Erfahrung wurde erfolgreich hinzugefügt.",
    deleteExperienceSuccessfully: "Deine Erfahrung wurde gelöscht.",
    editExperienceAddedSuccessfully: "Deine Erfahrung wurde erfolgreich bearbeitet.",
    deletePostSuccessfully: "Dein Beitrag wurde gelöscht.",
    leaveRoomSuccessfully: "Du wurdest aus diesem Raum entfernt.",
    createAnotherRoom: ", um weitere Räume zu erstellen.",
    threeMonthsForFree:
        "Buche dein Premium-Profil bis zum 31. Dezember 2022 und profitiere nicht nur von einer verlängerten kostenlosen Testphase von 3 Monaten, sondern sichere dir so auch einen Rabatt über 25 % für ein ganzes Jahr. Bitte beachte: Die unten stehenden Beträge enthalten den Rabatt noch nicht, er wird im Laufe des Bestellprozesses hinzugefügt, sobald du den Coupon-Code EDUSIIASTART22 eingibst.",
    successfullyAddedToRoom: "Du bist dem Raum erfolgreich beigetreten.",
    errorJoiningFullRoom: "Dieser Raum ist bereits voll. Ein Beitritt ist nicht möglich.",
    oneMonthForFree: "All members that sign up for a premium account from Jan 1st, 2023 will receive a 1-month free trial.",
    pricePage: "Finde",
    find: "eine Übersicht unserer Mitgliedschaftspakete. Auf diesem Weg kannst du auch ein neues Paket für deine Organisation auswählen.",
    joined: "Beigetreten im",
    opened: "Erstellt im",
    organizationRegisteredAs: "Deine Organisation ist registriert als eine:",
    request: "Beitrittsanfrage",
    requests: "Beitrittsanfragen",
    agreePrivacyPolicy: "Hiermit bestätige ich, dass ich die",
    agreePrivacyPolicy1: "gelesen habe und mit ihnen einverstanden bin",
    einzelpreis: "Einzelpreis",
    menge: "Menge",
    MwSt: "MwSt.",
    summeBrutto: "Summe Brutto",
    ContactInfo: "Kontaktinfo",
    contactInfoTooltip: "Wenn du möchtest, dass deine E-Mail-Adresse in ",
    contactInfoTooltip2: "deinem Profil für andere sichtbar ist, um dich",
    contactInfoTooltip3: " direkt kontaktieren zu können, ändere",
    contactInfoTooltip4: " dies bitte in deinen Datenschutz-Einstellungen.",
    locationTooltip: " Wenn du nicht möchtest, dass dein Ort",
    locationTooltip2: " für andere in deinem Profil sichtbar ist, ändere dies",
    locationTooltip3: "  bitte in deinen Datenschutz-Einstellungen.",
    contactInfoSettings: "Meine E-Mail-Adresse ist für andere  Mitglieder in meinem Profil sichtbar.",
    locationSettings: "Mein Ort ist für andere Mitglieder in meinem Profil sichtbar.",
    confirmEmail: "Bestätige deine E-Mail",
    successEmailConfirm: "Email bestätigen",
    noEventsYet: "Bald stehen hier Angebote!",
    pageNotFound: "Seite Nicht Gefunden",
    pageNotFound2: "Entschuldigung, wir können die Seite, die du suchst, leider nicht finden!",
    dashboard: "Übersichtsseite",
    confirmOrganizationEmail:
        "Um das Organisationsprofil zu aktivieren, das du gerade erstellt hast, bestätige bitte die E-Mail-Adresse über den Link, den wir dir gerade zugeschickt haben.",
    deletedUser: "Ehemaliges Mitglied",
    deletedOrganization: "Ehemalige Organisation",
    thankYouForTheSupport: "Vielen Dank für deine Unterstützung!",
    successfulEdufan:
        "Du hast erfolgreich deine eduFan-Mitgliedschaft abgeschlossen. Falls du Änderungen darin vornehmen möchtest, kannst du dies in dem entsprechenden Bereich deiner Einstellungen tun.",
    goPremiumSuccessful:
        "Du hast erfolgreich deine bezahlte Mitgliedschaft abgeschlossen. Falls du Änderungen darin vornehmen möchtest, kannst du dies in dem entsprechenden Bereich deiner Einstellungen tun.",
    confirm: "Bestätigen",
    confirmNewEmail: "Bitte bestätige deine neue E-Mail-Adresse über den Link, den wir dir per E-Mail zugeschickt haben, um deine neue Adresse zu nutzen.",
    event: "Angebot",
    yourUpdatingToTheFollowingPlan: " Du bist dabei, deine Mitgliedschaft wie folgt zu aktualisieren:",
    warning:
        "Bitte beachte, dass die Veränderungen ab der kommenden Abrechnungsperiode wirksam werden. Achtung: Wenn du dich noch in der Testphase befindest, wird die Änderung sofort wirksam und die restliche Testphase verfällt.",
    confirmEmailMessage2:
        "Wenn du dein Organisationsprofil mit einer anderen E-Mail-Adresse angelegt haben solltest, bestätige bitte auch diese E-Mail-Adresse.",
    addAdmin: "Admin hinzufügen",
    added: "Hinzugefügt",
    removeAdmin: "Admin entfernen?",
    removeAdminQuestion: " als Admin entfernen möchtest?",
    successfulUpgrade:
        "Du hast deine Mitgliedschaft erfolgreich zur nächsten Abrechnungsperiodvcxe aktualisiert. Sobald der neue Plan aktiv ist, siehst du die Änderungen in deinem Profil.",
    postiveNewEmailVerification: "Deine neue E-Mail-Adresse wurde erfolgreich verifiziert. Du kannst sie jetzt für deine Kontoanmeldung nutzen.",
    organizationEmailVerification: "Die E-Mail-Adresse deiner Organisation wurde erfolgreich verifiziert.",
    newOrganizationEmailVerification: "Deine neue E-Mail-Adresse wurde erfolgreich verifiziert und aktualisiert.",
    mln: "Mio",
    cantPostInRoom: "Nur Moderator:innen können in diesem Raum Inhalte teilen.",
    adminRemoved: "Admin entfernt",
    fileIsTooBig: "Die Dateigröße deines Bildes ist zu groß. Bitte lade eines mit max. 4 MB hoch.",
    creatorsAndManagers: "Gastgeber:in & Moderator:innen",
    uploadWithSpace: "B I L D",
    imageWithSpace: "H O C H L A D E N",
    moreThanRoomLimit1: "Wenn du mehr als ",
    moreThanRoomLimit2: "Räume erstellen möchtest, schreib uns eine Nachricht an",
    questionAndAnswers: "Fragen & Antworten",
    video: "Erklärvideo",
    help: "Hilfe",
    contact: "Kontakt",
    startTutorial: "Los geht’s!",
    onboarding: "Onboarding",
    skipTutorial: "Überspringen",
    videoCheckboxText: "Beim nächsten Anmelden nicht noch einmal anzeigen.",
    viedoWelcomeText: "Herzlich willkommen bei edusiia!",
    videoExplanationText: "Damit du dich hier gleich bestmöglich zurechtfindest, wollen wir dir kurz die wichtigsten Features unseres Netzwerks zeigen. Das dauert nur zwei Minuten und danach bist du sofort startklar.",
    pinPost: "anpinnen",
    unpinPost: "anpinnen aufheben",
    postsAndComments: "Beiträge & Kommentare",
    post: "Beitrag",
    comment: "Kommentar",
    seeFullPost: "siehe ganzen Beitrag",
    at: "in",
    seeMore: "Mehr anzeigen",
    exploreRooms: "Räume entdecken",
    deletedEvent: "Das Angebot wurde gelöscht.",
    hashtags: "Hashtags",
    addHashtag: "Hashtag hinzufügen",
    reachedMaxHashtags: "Maximalzahl erreicht",
    hashtagTooltip: "Erstelle deinen Hashtag mit einer dem Wort ",
    hashtagTooltip2: "vorangestellten Raute #, drücke die",
    hashtagTooltip3: ' Leertaste und klicke auf "Speichern".',
    results: "Ergebnisse",
    result: "Ergebnis",
    reactions: "Reaktionen",
    allReactions: "Alle",
    blurryHashtag: "Um deine eigenen Hashtags zu erstellen, klicke hier. ",
    educationNews: "Bildungs-News",
    readMore: "Mehr dazu",
    Invitations: "Anfragen",
    Invitation: "Anfrage",
    hashtag: "#hashtag",
    transferOwnership: "Inhaberschaft übertragen",
    transferInProgress: "Inhaberschaft wird übertragen",
    transferTooltip2: "Du bist Inhaber:in dieses Profils und kannst",
    transferTooltip3: " Admins hinzufügen/entfernen, das Profil löschen,",
    transferTooltip4: " die Email-Adresse ändern, Mitgliedschaft verwalten.",
    transferTooltip5: "Klicke hier, um die Inhaberschaft zu übertragen.",
    transferOwnershipParagraph:
        "An wen möchtest du die Inhaberschaft dieses Organisationsprofils übertragen? Bitte beachte, dass du die damit in Verbindung stehenden zusätzlichen Rechte (Admins hinzufügen/entfernen, Profil löschen, Email-Adresse ändern) abgibst und die Rechte eines Admins behältst. Mehr Infos in den ",
    transferOwnershipParagraph2: "unter “Mitgliedschaft”.",
    confirmTransfer: "Gib dein Passwort ein, um die Übertragung zu bestätigen:",
    successfullyOwnershipTransfer: "Die Inhaberschaft wurde erfolgreich übertragen!",
    onlyOwnerTooltip1: "Um die Inhaberschaft deines Organisationsprofils zu übertragen,  ",
    onlyOwnerTooltip2: "füge bitte zuerst ein anderes Mitglied als Admin hinzu, ",
    onlyOwnerTooltip3: "indem du auf das Plus unter der Überschrift “Admin” klickst.",
    NoInvitations: "Aktuell hast du keine unbeantworteten Anfragen.",
    seeAllEvents: "Alle Angebote unserer Premium-Mitglieder",
    newPostsInMyRooms: "Neue Beiträge in meinen Räumen",
    newPostsInOtherRooms: "Neue Beiträge in anderen Räumen",
    createdByUs: "Von uns erstellt...",
    memberIn: "Mitglied in...",
    noJoinedRooms1:
        "Hier siehst du normalerweise die neusten Beiträge in den Räumen, in denen du Mitglied bist. Es scheint, als seist du noch keinem Raum beigetreten (oder es wurden noch keine Beiträge in den Räumen geteilt). Um Räume zu finden, die für dich interessant sein können, nutze die ",
    roomSearch: "Raumsuche ",
    noJoinedRooms2: "oder gib ein Schlagwort oben in die allgemeine Suchleiste ein.",
    noOwnedRooms1:
        "Hier siehst du normalerweise die neusten Beiträge in den Räumen, die du erstellt hast. Es scheint, als hättest du noch keinen Raum hinzugefügt (oder es wurde noch kein Beitrag in deinem Raum geteilt). Klicke hier, um einen ",
    noOwnedRooms2: "Raum zu erstellen.",
    filterRooms: "Sortieren",
    newActivity: "Neue Aktivität",
    alphabetical: "Alphabetisch",
    firstJoined: "Zuerst beigetreten",
    lastJoined: "Zuletzt beigetreten",
    inWhichStateDoYouWork: "In welchem Bundesland/Kanton arbeitest du?",
    inWhichStateIsTheOrgLocated: "In welchem Bundesland/Kanton befindet sich die Organisation?",
    chooseYourState: "Wähle ein Bundesland/Kanton…",
    typeOfOrgYourInvolvedIn: "In welcher Art von Organisation bist du hauptsächlich aktiv?",
    whatTypeOfOrgDescribesYou: "Welche Art von Organisation beschreibt euch am besten?",
    chooseMostApplicable: "Wähle das Zutreffendste…",
    doYouwantToLeaveRoom: "Möchtest du wirklich nicht mehr Mitglied dieses Raumes sein?",
    continue: "Fortfahren",
    addLocation: "Ort hinzufügen",
    inactiveRoomNotice_1: "Dieser Raum ist aktuell nicht aktiv. Erfahre in den",
    inactiveRoomNotice_2: " mehr über mögliche Gründe.",
    error: "Fehler",
    directChat: "Chat",
    typeChatMessage: "Nachricht verfassen...",
    chatNoConversations: "Du hast noch keine Unterhaltungen.",
    leaveChat: "Unterhaltung löschen",
    doYouwantToLeaveChat: "Möchtest du diese Unterhaltung wirklich löschen?",
    unseenMessages: "Neue ungelesene Chatnachrichten",
    rommJoinQuestion: "Verifikationsfrage bei Beitrittsanfrage",
    maybeInteresing: "Könnte dir auch gefallen",
    shareNow: "Jetzt teilen",
    editNow: "Jetzt bearbeiten",
    discoverNow: "Jetzt entdecken",
    letsstart: "Los gehts!",
    fillNow: "Jetzt ausfüllen",
    loadMoreRooms: "Mehr Räume laden",
    tutorial_user_roomlist: "Der Austausch bei edusiia passiert in Räumen. In dieser Liste findest du die Räume, in denen du Mitglied bist. Per Lupe kannst du nach weiteren Räumen suchen.",
    tutorial_user_feed: "Dein Feed zeigt dir neue Beiträge anderer Mitglieder aus deinen Räumen. Zusätzlich stellt dein Feed weitere Inhalte vor, die dich interessieren könnten.",
    tutorial_user_feed_textbox: "Hier kannst du direkt ein spannendes Thema oder eine Frage eingeben und anschließend wählen, in welchem Raum dein Beitrag veröffentlicht werden soll.",
    tutorial_user_profile: "Klicke hier, um dein persönliches Menü zu öffnen. Per Klick auf deinen Namen kannst du dein Profil vervollständigen. Ein vollständigtes Profil hilft anderen, dich besser kennenzulernen. Und: Über die Profile anderer Mitglieder kannst du direkt mit ihnen chatten.",
    tutorial_user_chat: "Hier findest du die Nachrichten, die dir andere Mitglieder geschickt haben.",
    tutorial_user_search: "Neue Räume, Mitglieder und Inhalte findest du bequem über die Suchleiste.",
    tutorial_user_create_orga: "Neben deinem persönlichen Profil kannst du auch ein Profil für deine Bildungsorganisation anlegen - damit kannst du eigene Räume anlegen und deine Community zum Austausch einladen.",
    tutorial_user_switch_profile: "Du hast schon ein Organisationsprofil angelegt. Hier kannst du zwischen deinen Profilen wechseln.",
    tutorial_org_own_room: "Deine Organisation hat automatisch ihren eigenen Raum bei edusiia, den du moderieren und gestalten kannst. Lass uns direkt in deinen Raum springen",
    tutorial_org_room_types: "Räume können öffentlich, also für alle edusiia Mitglieder zugänglich, oder privat sein. Bei privaten Räumen hast du als Admin die volle Kontrolle, wer hier rein und mitdiskutieren darf.",
    tutorial_org_room_edit: "Mit diesem Button kannst du deine Raumeinstellungen bearbeiten, den Namen anpassen, ein Bild anfügen und Berechtigungen verwalten.",
    tutorial_org_room_invite: "Du kannst mit dem oberen Button edusiia Mitglieder in deinen Raum einladen oder per Einladungslink neue Personen in den Raum einladen, die noch nicht bei edusiia registriert sind.",
    tutorial_org_premium: "Mit einem Premium-Profil kann deine Bildungsorganisation mehrere Räume anlegen und größere Communities betreuen. In der kostenlosen Version kannst du bis zu 10 Mitglieder in deinem Raum versammeln.",
    tutorial_org_profile: "Dein Organisationsprofil hat zusätzliche Funktionen, die deine Präsenz bei edusiia noch wirkungsvoller machen können. Lass uns rein schauen!",
    tutorial_org_profile_motivation: "Ein gut ausgefülltes Profil gibt anderen Mitgliedern direkt mehr Orientierung zu deiner Organisation.",
    tutorial_org_events: "Hier kannst du auf Veranstaltungen, neue Themen oder Produkte deiner Organisation hinweisen, die du besonders hervorheben möchtest. Mit einem Premium-Profil werden diese Angebote allen Mitgliedern auf ihren Dashboards angezeigt.",
    tutorial_org_admin_view: "Wenn du weitere Admins hinzufügen oder die Inhaberschaft des Profils ändern möchtest, kannst du das hier machen.",
    tutorial_org_welcome_text: "Willkommen auf der Übersichtsseite deines Organisationsprofils! Wir würden dir gerne kurz vorführen, wie du edusiia als Bildungsorganisation ideal nutzen kannst."
}
