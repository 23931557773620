import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import { Organization } from "../../../Types/Organization"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { resetOrganizationAccountError, setOrganizationAccountErrorMessage, setOrganizationData } from "../../Shared/SharedSlices/OrganizationsSlice"
import { profileSettings } from "../../User/UserSettings/UserSettingsProfileServiceRequests"
import { getUserPasswordConfirmError, resetUserPasswordConfirmError } from "../../User/UserSettings/UserSettingsSlice"
import { AccountSettings } from "./OrganizationSettingsInterfaces"
import { getOrganizationProfileSettingsState } from "./OrganizationSettingsSlice"

export const organizationProfileHashtagPostRequest = (hashtag_list: string[], organization: AccountSettings | Organization, verification_email_sent: any) => async (dispatch: any) => {
    try {
        const response = await HTTPService.post(`/htag/org/${organization.id}/`, { hashtag_list: hashtag_list }, headers)
        if (response.status === 201) {
            if (verification_email_sent) {
                dispatch(
                    setModalData({
                        open: true,
                        confirmNewEmailMessage: true,
                    })
                )
            } else {
                dispatch(setModalData({ open: true, organizationSettings: true }))
            }

            dispatch(setOrganizationData({ ...organization, hashtags: response.data.hashtags }))
            dispatch(getOrganizationProfileSettingsState(false))
            dispatch(resetOrganizationAccountError())

        }
    } catch (error: any) {
        console.log(error)
    }
}

export const PatchOrganizationTutorialProgressRequest = (organizationId: number, tutorial_progress: number) => {
    const response = HTTPService.patch(`/organization/${organizationId}/`, { 'tutorial_progress': tutorial_progress }, headers)
    return response
}

export const patchOrganizationAccount =
    (organizationId: string, userId: number, accountSettings: profileSettings | AccountSettings) => async (dispatch: any) => {
        try {
            const response = await HTTPService.patch(`/organization/${organizationId}/`, accountSettings, headers)
            if (response.status === 200) {
                dispatch(organizationProfileHashtagPostRequest(accountSettings.hashtag_list!, response.data, response.data.verification_email_sent))
            }
        } catch (error: any) {
            const errors = {
                msg: error.response.data,
                status: error.response.status,
            }
            dispatch(setModalData({ open: true, organizationSettings: true }))

            await dispatch(
                setOrganizationAccountErrorMessage({
                    message: errors.msg,
                    status: errors.status,
                })
            )
        }
    }

export const ownerConfirmPasswordPostRequest =
    (password: string, setData: profileSettings | AccountSettings, organizationId: string, userId: number) => async (dispatch: any) => {
        try {
            const response = await HTTPService.post("/confirm-existing-password/", { password: password }, headers)
            if (response.status === 200) {
                dispatch(
                    setModalData({
                        open: false,
                        userConfirmPasswordModalState: {
                            status: false,
                            setData: { first_name: "", last_name: "", email: "", password: "", confirm_password: "", location: "" },
                            type: "",
                        },
                    })
                )

                dispatch(patchOrganizationAccount(organizationId, userId, setData))
                await dispatch(resetUserPasswordConfirmError())
            }
        } catch (error: any) {
            dispatch(getUserPasswordConfirmError(error.response.data.message))
        }
    }
