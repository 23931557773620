import React, { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import "../../../Styles/main.scss"
import { LoadingAnimation, SmileyFace } from "./SvgIcons"
import { RootState } from "../../../App/store"
import { useNavigate } from "react-router-dom"
import { RoleType } from "../../../App/enums"
import FeedItem from "./FeedItem"
import FeedCTASlider from "./FeedCTASlider"
import TutorialBubble from "./TutorialBubble"
import { selectTranslations } from "../../../App/i18n/i18nSlice"

function FeedContainer(props: { type: RoleType.USER | RoleType.ORGANIZATION; isLoading: boolean }) {
    const posts = useSelector((state: RootState) => state.RoomPostsSlice.roomPostsSlice.posts)

    return (

        <>
            {props.isLoading && posts.length == 0 && (
                <>
                    <div className="feed-item__loading-card">
                        <LoadingAnimation />
                    </div>
                    <div className="feed-item__loading-card">
                        <LoadingAnimation />
                    </div>
                    <div className="feed-item__loading-card">
                        <LoadingAnimation />
                    </div>
                </>
            )}

            {posts?.map((post, index) => {
                if (index == 0) {
                    return (
                        <>
                            <FeedItem key={post.id} isLoading={false} type={props.type} post={post} />
                            <FeedCTASlider key={`cta-${post.id}`} />
                        </>
                    )
                }
                return <FeedItem key={post.id} isLoading={false} type={props.type} post={post} />
            })}
        </>
    )
}

export default FeedContainer
